import React from "react"
import intl from "react-intl-universal"
import { wrapperInputCn, labelCn, inputAreaCn } from "../styles"
import AdditionalInfo from "./AdditionalInfo"

const MagicSelect = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue, disabled}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string | number, disabled?: boolean}) => {
  return (
    <div className={`${wrapperInputCn} sm:w-1/3`}>
      <div className={`${hasInfo && 'flex flex-row space-x-2'}`}>
        <label htmlFor={name} className={labelCn}>{labelText}</label>
        {hasInfo && <AdditionalInfo tooltipContent={tooltipContent || ''} />}
      </div>
      <select
        id={name}
        name={name}
        className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)]`}
        value={selectedValue}
        disabled={disabled}
        onChange={onChange}>
        <option value="automatic">{intl.get('automatic')}</option>
        {content.map(([key, value], index) => {
          return <option value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

export default MagicSelect