import React, { useEffect, useState } from "react"

const CreationInProgress = ({ title, description }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h2>
      <p className="text-lg mb-4 text-gray-600">{description}</p>
      <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden relative">
        <div
          className="h-full rounded-full transition-all duration-75"
          style={{
            width: `${progress}%`,
            backgroundColor: '#915fee',
          }}
        ></div>
      </div>
    </div>
  )
}

export default CreationInProgress