import { ContentType } from "graphql/schemas/action/Action"

export const contentTypes: Record<ContentType, string> = {
  multiple_choice: "Multiple Choice",
  qa: "QA",
  photo: "Photo",
  image: "Image",
  text: "Text",
  quote: "Quote",
  certificate: "Certificate",
  assessment: "Assessment",
  upload: "Upload",
  rating: "Rating",
  word_puzzle: "Word Puzzle"
}

export const useCases = {
  training: "Training",
  onboarding: "Onboarding",
  sales_enablement: "Sales Enablement",
  leadership_development: "Leadership Development",
  operations: "Operations",
  safety_and_compliance: "Safety and Compliance",
  partner_engagement: "Partner Engagement",
  customer_engagement: "Customer Engagement"
}

export const languages = {
  ar: "Arabic",
  bn: "Bengali",
  bg: "Bulgarian",
  zh: "Chinese",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  et: "Estonian",
  fi: "Finnish",
  fr: "French",
  de: "German",
  el: "Greek",
  iw: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  lv: "Latvian",
  lt: "Lithuanian",
  no: "Norwegian",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  es: "Spanish",
  sw: "Swahili",
  sv: "Swedish",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  vi: "Vietnamese"
}

export const sessionLength = {
  3: '3 actions',
  6: '6 actions',
  10: '10 actions'
}

export const programLength = {
  3: '3 sessions',
  6: '6 sessions',
  10: '10 sessions'
}

export const voice = {
  professional: 'Professional',
  casual: 'Casual',
  inspirational: 'Inspirational',
  playful: 'Playful',
  educational: 'Educational',
  story_driven: 'Story-Driven'
}

export const programDifficulty = {
  basic: 'Basic',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  universal: 'Universal'
}

export const audienceOptions = {
  individual: 'Individual',
  manager: 'Manager',
  senior: 'Senior',
  Other: 'Other',
}

export const matchTypes = {
  low_precision: 'Creative Exploration',
}
