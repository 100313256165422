import React from "react";
import { inputAreaCn } from "../styles";
import intl from "react-intl-universal";


const MagicSelectPrecision = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string}) => {
  return (
    <div className="flex flex-col space-y-2 w-full py-2 bg-[#F9F8F8] rounded-lg sm:w-52">
      <select
        name={name}
        id="magicActionTitle2"
        className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)]`}
        value={selectedValue}
        onChange={onChange}>
        <option value="high_precision">{intl.get('high_precision')}</option>
        {content.map(([key, value], index) => {
          return <option className="pr-1" value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

export default MagicSelectPrecision