import { gql } from '@apollo/client'

export type ImportCourseQuestionsVars = {
  courseId: string;
  questionsType: 'questions' | 'assessments';
  questionsUrl: string;
  organizationId: string;
}

export const importCourseQuestionsMutation = gql`
  mutation importCourseQuestions ($courseId: String!, $questionsType: String!, $questionsUrl: String!, $organizationId: String!) {
    importCourseQuestions (courseId: $courseId, questionsType: $questionsType, questionsUrl: $questionsUrl, organizationId: $organizationId)
  }
`