import Tippy from '@tippyjs/react'
import React from 'react'

const MultiChoiceNoteIcon = ({ className, tooltip }: { className: string, tooltip?: string }) => {
  return (
    <div>
      <Tippy placement="bottom" content={tooltip} disabled={!tooltip}>
      <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} >
        <path d="M20 0.5H0V24.5H20V0.5Z" fill="url(#pattern0_12_50)"/>
        <defs>
        <pattern id="pattern0_12_50" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_12_50" transform="matrix(0.04 0 0 0.0314754 0 -0.00360656)"/>
        </pattern>
        <image id="image0_12_50" width="25" height="32" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAgCAIAAABo/kRRAAACpElEQVR4nGP8//8/A15w8c69UzdvivDxuZoY8XBy4lHJgt+gnlVrJ27YDLFPVlRkZXW5nLgYLsVMeAw6d+vOxA2bRfn5muKig6wtHr9+Uz1vEZnuOn79xv///wsD/WPdnP/9/3/kyvUTN27++/+fiZERn1nP376bsmHznWfP/v5DBN+TN28YGBgu33/w////Ry9efvz27efv32FNbQwMCLP4ubk8TY1D7G0ZGBgY//////HLV+ey6hfv3+NyoLSQ0LsvX77/+oVLQXVkWKafNwsDA8P2U6dfvH/vaWJUGxPJwszMwMAQ2NDy9O07uNKn796haW6IifQyN2VgYLjz7FlsZ9/8nbuhZj17956BgcHdxAgeR+rSUs/ff8DlCjZmZl1FBSkRYQYGBikRYRlhoYev3zDgCvtFlaW4DMID8KWJUbNoaxaWePzy7Xtq38QvP37MLMgVExRYumf/rz9/kBUwMjD4WpmLCwoSNuvUzZuHr15nYGDYc+68s6FB9YLFmGoEeXiC7W0Im2WuoeGop/vlx3c3EyNRfv7O5ITfqO5iYGSw1dMhyo/cnByLK0vg3GgXR0w1WAHt4/Hhy1c3Hz8h1Swsfrzz9JlbRe3vv3+XV5ToKStFtXWhhxcDQ3NCjJmmBmGz3n/+DEkErz5+/Pn796X7D/5h1C+vP34iyl2mGurTcjO/fP/hb23JzMR0a/6sP//+IitgZGDk5uQgyiwGBgY/Kws4m4OdDasaTDBY8yO1zWJiYmJgYPjz9y8hxdjB779/mZmYGCBhryYtxcDAMGfH7n///kPqIeLBnefPn717rykrwwCpH//++xfb1n3o6jXy3MXByjq3KM/eQI8R0u74++/f7jPnbj999pdQswcNCHBxORjoKUiIQ91FnnMwAQDAVgwnVfQehQAAAABJRU5ErkJggg=="/>
        </defs>
      </svg>

      </Tippy>
    </div>
  )
}

export default MultiChoiceNoteIcon