import Tippy from '@tippyjs/react'
import React from 'react'

const AssessmentCheckIcon = ({ className, tooltip }: { className: string, tooltip?: string }) => {
  return (
    <div>
      <Tippy placement="bottom" content={tooltip} disabled={!tooltip}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} >
        <path d="M20 0H0V20H20V0Z" fill="url(#pattern0_12_41)"/>
        <defs>
        <pattern id="pattern0_12_41" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_12_41" transform="matrix(0.035784 0 0 0.0344828 -0.000975927 0)"/>
        </pattern>
        <image id="image0_12_41" width="28" height="29" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAIAAAA2M5tmAAACfElEQVR4nM3WS0wTQRgH8G+aYqHblvJMBFr6AEEFH1EPhtDYGBNvxmjZgBcjRq892N247cGEhXQ5ePJAIolGkxYwnjUmHgQJEcUD8hBC7Btoi7DdbRtOjoeamJSlkG0PfJdJZia/fMlM5j8IYwylLmVumFlcfvdtTshkZEOqsmOX2lpvdXchhBDG+O3kZ+fIaEla7rtiG37YjzDG3U5XMJ7g7t9tbWyQzaUyWXr0ZZznZ589VQJAMJFsqq3pu2ovsk3bl69vpqajiaQCADDGCoSKFAEAIQQAGIOieGtvHT00uBn3vHi1LYh580rZYmBjs4f1bmzv6NRqirxdAvTX+kYP693c4duaGu9dv5a3Kgddi62TLBfn+XZD07iHrtHpikXXYrEelkvwqZNGw7ibrtZp9+6ROKhociu7uysprkZjjgFvgk+dbjZOeKRFCXQ1ErU/enzzyeCOmM5bWolEyQFvMiV0mIxjbrpKKy1KoERFOVGuWgyFe4c4Pv3f/RmOkKw3KQhnzM1jbrpKq9lPlEAba2vH3HSNVrsQDPcNDafSGQBYDoVJltsSxLNmk5+h9ZpCogQKAO1Gg5+hqjWa+UCod2h4ZnGJHOR+i+I5i9nnpio1RGFRGgWAUyajj3HpCWI+EHSw3LaYPm+1+BiqkjhY3BcFgA6zyc+49IQaAC60WH2MS0eoDyNC4XvaaTH7GWri0xRFOrTqikOKB6A5t9NiPjyXq6P39BVCEUJ/ShGluTxGCJQAYKqvC8YTrz98tDYcly0K2ezkjwUAMNTX5XJ/2jnyvCS5f8du4x70o5w1u7zyfu67mMnK5lRlZRdPtNzouvzvh1J8g3n1Fwte9VKdLy5MAAAAAElFTkSuQmCC"/>
        </defs>
      </svg>
      </Tippy>
    </div>
  )
}

export default AssessmentCheckIcon
