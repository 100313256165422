import React from "react"
import intl from "react-intl-universal"

const OutlineContent = ({ parsedData, expanded }: { parsedData: any, expanded: boolean }) => {

  return (
    <div className={`text-black text-sm pr-4 ${expanded ? 'h-screen': 'h-96'} scrollable-div`}>
      <div><strong>{intl.get('gnowbe_ai_title_of_program')}:</strong> {parsedData.title}</div>
      <br />
      {parsedData.sessions.map((session, index) => {
        if (index === 0) return null
        return <div key={index}>
          <div><strong>{session.sessionNumber}</strong></div>
          <div><strong>{intl.get('title')}:</strong> {session.title}</div>
          <div><strong>{intl.get('global_description')}:</strong> {session.description}</div>
          <br />
        </div>
      })}
    </div>
  )
}

export default OutlineContent