import Tippy from "@tippyjs/react"
import React from "react"

const AdditionalInfo = ({ tooltipContent, interactive, className }: { tooltipContent: string | React.ReactNode, interactive?: boolean, className?: string }) => {
 return (
  <Tippy content={tooltipContent} interactive={interactive} trigger="click">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "24px", height: "24px", overflow: "visible", fill: "#808084" }} viewBox="0 0 24 24" className={`${className} h-5 w-5`}>
      <path d="M0 0h24v24H0z" fill="none">
      </path>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
      </path>
    </svg>
  </Tippy>
 )
}

export default AdditionalInfo