import { BASE_API_URL } from "constants/General"
import { CreateSessionOrActionProps, CreateProgramProps, CreateProgramOutline } from "./types"

export const createSessionOrAction = async ({courseId, chapterId, organizationId, companyId, inputData, type, uploadedFiles, token, dummy, generateAiImages, totalCreditsCount, totalTokensCount} : CreateSessionOrActionProps) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createChapterOrAction`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      courseId,
      chapterId,
      organizationId,
      type,
      inputData,
      uploadedFiles,
      dummy,
      generateAiImages,
      totalCreditsCount: totalCreditsCount || 0,
      totalTokensCount: totalTokensCount || 0,
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

export const createProgram = async ({organizationId, inputData, uploadedFiles, token, dummy, generateAiImages, totalCreditsCount, totalTokensCount} : CreateProgramProps) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createCourse`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      organizationId,
      inputData,
      uploadedFiles,
      dummy,
      generateAiImages,
      totalCreditsCount: totalCreditsCount || 0,
      totalTokensCount: totalTokensCount || 0,
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

export const createProgramOutline = async ({ organizationId, inputData, uploadedFiles, dummy, token, previousOutline} : CreateProgramOutline) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createCourseOutline`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      organizationId,
      inputData,
      uploadedFiles,
      dummy,
      previousOutline
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}