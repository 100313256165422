import React from 'react'
import { UpsellModal } from "components/modals/UpsellModal"
import { showModal } from "components/utils/CustomModal"
import { GetCurrentSubscriptionRes } from "graphql/queries/user/getUser"
import intl from "react-intl-universal"
import { track } from "utils/track"

export const checkAndShowAiCreditsModal = (credits, userData: GetCurrentSubscriptionRes|undefined, organizationId, sendBillingAdminLockedFeatureEmail) => {
  const isBillingAdmin = userData?.user.billingManagerOrganizations.includes(organizationId) || false

  const modalType = isBillingAdmin
    ? 'ai_credits_limit_reached_billing_admin' as "ai_credits_limit_reached_billing_admin"
    : 'feature_locked_ai_credits_user_notify_admin' as "feature_locked_ai_credits_user_notify_admin"

  if (credits.totalCredits > credits.availableCredits) {
    const modal = showModal({
        title: intl.get('home_group_invite'),
        component: <UpsellModal type={modalType} workspaceId={organizationId} track={modalType} close={() => {
          modal.close()
        }} sendEmail={sendBillingAdminLockedFeatureEmail} />,
        onlyContent: true,
        className: 'add-action-modal',
      })

      if (isBillingAdmin) {
        sendBillingAdminLockedFeatureEmail({
          variables: {
            feature: 'feature-locked-ai-credits-upsell-modal',
            organizationId: organizationId
            },
        })
        track({
          event: 'Email Sent: Ai Credits Limit Reached - Is Billing Admin',
          variables: {
            organizationId,
          }
        })
      }

    return true
  }
  return false
}