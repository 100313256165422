import React, { Dispatch, SetStateAction, useEffect } from 'react'
import intl from 'react-intl-universal'
import { GetCurrentSubscriptionRes, GetUserAndActiveCourseResponse } from 'graphql/queries/user/getUser'
import { HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
import { CompanyBox } from 'components/home/CompanyBox'
import { CategoryHeader } from 'components/home/CategoryHeader'
import { MobilePageHeader, PageHeader } from 'components/home/PageHeader'
import { HomeCategory } from 'components/home/HomeCategory'
import { ExploreBanner } from 'components/explore/ExploreBanner'
import { hideMarketing, isIndependentCurator, User } from 'graphql/schemas/user/User'
import { Button } from 'components/utils/Button'
import { BASE_DASHBOARD_URL, DASHBOARD_DEEPLINK } from 'constants/General'
import { useLazyQuery } from '@apollo/client'
import { getMyReferralBannerDataQuery, GetMyReferralBannerDataRes } from 'graphql/queries/user/getMyReferralBannerData'
import { history } from 'utils/history'
import { useNavigate } from 'react-router'
import { HasOrganizationFeatures } from 'constants/Routes'
import { CreateCourseButton } from 'components/navbar/course-list/CourseListContainer'
import { GetOrganizationsRes } from 'graphql/queries/organization/getOrganziations'

type HomeListsProps = {
  render: boolean;
  home: HomescreenData|null|undefined;
  userData: GetUserAndActiveCourseResponse|undefined;
  subData: GetCurrentSubscriptionRes;
  setTab?: (id: string) => void;
  workspaceName?: string,
  sandboxWorkspaceName?: string,
  isEditing: boolean,
  showExploreAndLearnModal: () => void,
  creatorInOrganizationsLength: number,
  setActiveWorkspaceId: Dispatch<SetStateAction<string>>;
  orgData: GetOrganizationsRes|undefined;
}

export const HomeLists = ({
  render,
  home,
  userData,
  subData,
  setTab,
  workspaceName,
  sandboxWorkspaceName,
  isEditing,
  showExploreAndLearnModal,
  creatorInOrganizationsLength,
  setActiveWorkspaceId,
  orgData,
}: HomeListsProps) => {
  if (!userData) return null

  const navigate = history.navigate || useNavigate()

  const showUpsellAd = isIndependentCurator(subData.user)

  const renderHomeCategory = React.useMemo(() => {
    return (home?.homescreenData?.courseCategories || []).map((category) => {
      return (
        <HomeCategory
          key={category.id}
          subData={subData}
          activeSub={userData?.app.currentCompanyIdCourseId}
          category={category}
          favoritedSubscriptions={subData.user.favoritedSubscriptions}
          setTab={setTab}
        />
      )
    })
  }, [home?.homescreenData?.courseCategories])

  const renderCompanyBox = (homeCompany: any) => {
    return homeCompany.companies.map((company) => {
      return (
        <CompanyBox
          key={company.id}
          isActive={userData?.subscription.companyId === company.id || false}
          subData={subData}
          company={company}
          pinnable={true}
          courses={undefined}
          expanded={null}
          onExpand={() => {
            setTab && setTab(`groups#all#${company.organizationId}-${company.id}`);
          }}
        />
      );
    });
  }

  return (
    <>
      <MobilePageHeader tab="home" showExplore={subData.user.features.includes('show_explore_tab')} setTab={setTab} />

      <div className="px-4 md:px-6">
        <HasOrganizationFeatures features={['generateCourseAi']}>
          <div className="bg-magic-lighter border border-magic rounded-md px-6 py-4 mb-4 font-medium flex flex-row justify-between">
            <div className="flex flex-row">
              <div className="hidden md:flex items-center justify-center my-2 mr-4">
                <img className="w-10 h-10" src="/images/wand-magic-sparkles.svg" alt="magic wand" />
              </div>
              <div className="mr-2">
                <div className="text-magic font-noto-sans font-semibold text-[20px] leading-[38px]">Introducing the Gnowbe AI Magic Creator</div>
                <div className="text-deepgray font-noto-sans">Now available with free credits — start exploring the magic today!</div>
              </div>
            </div>
            <CreateCourseButton userId={subData.user.id} hideCreateCourseButton={hideMarketing(subData.user)} aiBanner isEditing={isEditing} showExploreAndLearnModal={showExploreAndLearnModal} creatorInOrganizationsLength={creatorInOrganizationsLength} setActiveWorkspaceId={setActiveWorkspaceId} orgData={orgData} />
          </div>
        </HasOrganizationFeatures>

        <PageHeader
          title={`${(workspaceName && `${intl.get('home_tab_home')} › ${workspaceName}`) || intl.get('home_tab_home')}`}
          hideBorder={true}
        />

        {sandboxWorkspaceName && (sandboxWorkspaceName === workspaceName) && (
          <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium">
            {intl.get('sandbox_description_1')}
            {intl.get('sandbox_description_2')}
          </div>
        )}

        {showUpsellAd && <ExploreBanner name={subData.user.profile.fullName || 'User'} type="ad" />}

        <ReferralBanner user={subData.user} />

        <div className="pb-4 space-y-10">
          {/* Programs */}
          {!render
            ? <HomeCategory
                subData={subData}
                category={null}
                favoritedSubscriptions={[]}
              />
            : <>{renderHomeCategory}</>
          }

          {/* My Groups */}
          {render && (
            <div>
              <CategoryHeader
                title={intl.get('home_category_company_recent')}
                showBorder={false}
              />

              {(home?.homescreenData.companyCategories || []).map((homeCompany) => {
                return (
                  <div key={homeCompany.id} className="mt-2 space-y-4">
                    {renderCompanyBox(homeCompany)}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default HomeLists

export const ReferralBanner = ({ user }: { user: User }) => {
  const [getReferrals, { data, error, loading }] = useLazyQuery<GetMyReferralBannerDataRes>(getMyReferralBannerDataQuery)

  useEffect(() => {
    if (!user.features.includes('referrals')) return
    getReferrals()
  }, [])

  if (!user.features.includes('referrals') || !data) return null

  const referrer = data.ref.referrerName
  const expDate = data.ref.daysLeftToJoin
  const redirectUrl = user.billingManagerOrganizations.length > 0
    ? `${DASHBOARD_DEEPLINK}/organization/${user.billingManagerOrganizations[0]}/upgrade`
    : BASE_DASHBOARD_URL

  return (
    <div className="w-full px-8 py-5 flex items-center text-deepgray gap-6 mb-8 -mt-4 bg-[#D4E7EB]">
      <div className="flex-1">
        <div className="text-xl font-bold">{intl.get('referral_title', { 0: data.ref.discountPercent})}</div>
        <div>{intl.getHTML('referral_text', { 0: referrer, 1: data.ref.discountPercent, 2: expDate })}</div>
      </div>

      <div>
        <Button type="primary" text={intl.get('referral_button')} onClick={() => window.open(redirectUrl)} />
      </div>
    </div>
  )
}
