import React, { useEffect, useState } from "react"
import { MagicCheckboxProps } from "../types"
import AdditionalInfo from "./AdditionalInfo"

const MagicCheckbox = ({name, setMagicData, fieldKey, disabled, additionalInfoText } : MagicCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(true)

  useEffect(() => {
    setMagicData((prevData) => ({
      ...prevData,
      [fieldKey]: isChecked,
    }))
  }, [isChecked])

  const handleChange = (event) => {
    setIsChecked(event.target.checked)
  }

  return (
    <div className="flex flex-row space-x-2">
      <label>
        <input
          type="checkbox"
          className="focus:ring-magic h-4 w-4 text-magic border-magic rounded mr-2"
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        {name}
      </label>
      {additionalInfoText && <AdditionalInfo tooltipContent={<div>{additionalInfoText}</div>} interactive />}
    </div>
  )
}

export default MagicCheckbox