import React from "react"

const StepTitle = ({ title, description }: { title: string, description: string }) => {
  return (
    <div className="mb-8">
      <div className="text-lg font-bold mb-1">
        {title}
      </div>
      <div className="text-sm">
        {description}
      </div>
    </div>
  )
}

export default StepTitle