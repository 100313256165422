import React from 'react'
import magicStarsAnimation from 'common/json/lottie/MagicStarsAnimation.json'
import Lottie from 'lottie-react'
import intl from 'react-intl-universal'

const MagicCreationInProgressBanner = ({ className, isCourseItem }: { className?: string, isCourseItem?: boolean }) => {
  return ( <div className={"absolute top-0 left-0 right-0 flex items-center justify-center space-x-3 bg-magic w-full mx-auto py-2 text-xs text-zinc-50 z-10" + (className ? ` ${className}` : "") + (isCourseItem ? " rounded-t-md" : "")}>
    <Lottie className="w-4 h-4" animationData={magicStarsAnimation}/>
    <div>{intl.get('gnowbe_ai_program_creation_progress')}</div>
  </div> )
}

export default MagicCreationInProgressBanner