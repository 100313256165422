import { gql } from '@apollo/client'

export type GetGnowbeAiLimitPerOrganizationRes = {
  res: {
    addon: string;
    title: string;
    description: string;
    price: number;
    freeCredits: number;
    status: 'active' | 'inactive';
    count: number;
    limit: number;
    feature: 'ai' | 'translation',
    freeCreditsLeft: number,
    addonBonusCredits: number,
    freeCreditsForCurrentPeriod: number,
    remainingCredits: number,
    step: number,
    stepMin: number,
    stepMax: number,
    countUnit: 'words' | 'credits',
    billingCycle: 'year' | 'month' | 'quarter' | 'null',
  }
}

export type GetGnowbeAiLimitPerOrganizationVars = {
  organizationId: string;
}

export const GetGnowbeAiLimitPerOrganizationQuery = gql`
  query GetGnowbeAiLimitPerOrganization ($organizationId: String!) {
    res: getGnowbeAiLimitPerOrganization (organizationId: $organizationId){
      addon
      title
      description
      price
      count
      limit
      freeCredits
      status
      feature
      freeCreditsLeft
      addonBonusCredits
      freeCreditsForCurrentPeriod
      remainingCredits
      step
      stepMin
      stepMax
      countUnit
      billingCycle
    }
  }
`

