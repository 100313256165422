import { XMarkIcon } from "@heroicons/react/24/outline"
import { PresignedUrl, getPresignedUrl } from "actions/files"
import { showModal2 } from "components/utils/CustomModal"
import { MiniLoader } from "components/utils/MiniLoader"
import { BASE_API_URL } from "constants/General"
import { useAppState } from "graphql/hooks/useAppState"
import React, { useEffect, useRef, useState } from "react"
import intl from "react-intl-universal"
import { trackAiFileUpload, trackButtonEnhanced } from "utils/track"
import { labelCn } from "../styles"
import { MagicUploaderProps, CustomFile, FileData, MagicType } from "../types"
import AdditionalInfo from "./AdditionalInfo"
import MagicSelectPrecision from "./SelectPrecision"
import { matchTypes } from "../constants"
import { Button } from "components/utils/Button"

const MagicUploader = ({ onChange, magicData, setMagicData, showUploadIndicator, setShowUploadIndicator, selectedFiles, setSelectedFiles, typeOfCreator, courseId, organizationId }: MagicUploaderProps) => {
  const appState = useAppState()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const previousFilesLength = useRef(selectedFiles.length)

  const [fileQueue, setFileQueue] = useState<CustomFile[]>([])
  const [isProcessing, setIsProcessing] = useState(false)

  // after all files have been removed, trigger the upload process to recalculate credits and clear the uploaded files data
  useEffect(() => {
    if (!appState) return
    const recalculateCredits = async () => {
      if (previousFilesLength.current > 0 && selectedFiles.length === 0) {
        setMagicData((prevData) => ({
          ...prevData,
          uploadedFilesData: [],
        }))
      }

      previousFilesLength.current = selectedFiles.length
    }
    recalculateCredits()
  }, [selectedFiles.length, magicData.uploadedFilesData])

  if (!appState) return null

  const handleInputButtonClick = () => {
    fileInputRef?.current?.click()
  }

  const isAudioOrVideoFile = (file: CustomFile) => {
    const audioTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3', 'audio/mp4', 'audio/aac']
    const videoTypes = ['video/mp4', 'video/x-m4v', 'video/ogg', 'video/webm', 'video/quicktime']

    return audioTypes.includes(file.type) || videoTypes.includes(file.type)
  }

  const hasAudioOrVideoFiles = selectedFiles.some(isAudioOrVideoFile)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      alert('No file selected')
      return
    }

    const newFile: CustomFile[] = Array.from(event.target.files).map((file) =>
      Object.assign(file, {
        urlS3: null,
        urlGemini: null,
        geminiDisplayName: null,
        geminiName: null,
        filePrecision: 'high_precision',
        uploadProgress: 0,
        uploadStatus: 'pending' as const,
      })
    )

    setSelectedFiles((prevFiles: CustomFile[]) => {
      // Prevent adding more than 10 files
      if (prevFiles.length + newFile.length > 10) {
        showModal2({
          title: intl.get('magic_file_upload_limit_reached'),
          content: intl.get('magic_upload_limit_reached_text'),
          destructive: true,
          primaryText: intl.get('global_close'),
          secondaryButton: false,
        })
        return prevFiles
      }

      return [...prevFiles, ...newFile]
    })

    if (newFile) {
      setFileQueue((prevQueue) => [...prevQueue, ...newFile])
    }
  }

  useEffect(() => {
    const processQueue = async () => {

      if (isProcessing || fileQueue.length === 0) {
        return
      }
      setIsProcessing(true)
      setShowUploadIndicator(true)

      const file  = fileQueue[0]

      try {
        const url = await uploadFileToS3(file)
        const geminiResponse: { data:FileData[] } = await uploadFileToGemini(appState, [url])
        const geminiData = geminiResponse.data[0]

        setSelectedFiles((prevFiles: CustomFile[]) => {
          const fileIndex = prevFiles.findIndex((f) => f.name === file.name)
          if (fileIndex === -1) return prevFiles

          const updatedFile = Object.assign(prevFiles[fileIndex], {
            urlS3: url,
            urlGemini: geminiData.uri,
            geminiDisplayName: geminiData.displayName,
            geminiName: geminiData.name,
            filePrecision: 'high_precision',
            uploadProgress: 100,
            uploadStatus: 'uploaded' as const,
          })

          const updatedFiles = [
            ...prevFiles.slice(0, fileIndex),
            updatedFile,
            ...prevFiles.slice(fileIndex + 1),
          ];

          return updatedFiles;

        })

        setMagicData((prevData: MagicType) => ({
          ...prevData,
          uploadedFilesData: [
            ...prevData.uploadedFilesData,
            geminiData,
          ],
        }))

        setMagicData((prevData) => ({
          ...prevData,
          uploadedFilesData: prevData.uploadedFilesData.map((fileData) => {
            if (fileData.displayName === file.geminiDisplayName) {
              return {
                ...fileData,
                filePrecision: 'high_precision',
              }
            }
            return fileData
          }),
        }))

        trackAiFileUpload({
          type: typeOfCreator,
          fileUrl: geminiData.uri,
          userId: appState.loggedInAs.uid,
          courseId,
          organizationId,
        })

        setFileQueue((prevQueue) => prevQueue.slice(1))

      } catch (error) {
        console.error('Error processing file:', error)
        setFileQueue([])
      } finally {
        setIsProcessing(false)

        if (fileQueue.length <= 1) {
          setShowUploadIndicator(false)
        }
      }
    }
    processQueue()
  }, [fileQueue, isProcessing])


  const handleRemoveFile = async (indexToRemove: number) => {
    setSelectedFiles((prevFiles: CustomFile[]) => {
      const fileToRemove = prevFiles.find((_, index) => index === indexToRemove)
      const updatedSelectedFiles = prevFiles.filter((_, index) => index !== indexToRemove)

      setMagicData((prevData) => ({
        ...prevData,
        uploadedFilesData: prevData.uploadedFilesData.filter(
          (fileData) => fileData.displayName !== fileToRemove?.geminiDisplayName
        ),
      }))

      return updatedSelectedFiles
    })
  }

  function areAllFilesUploaded(selectedFiles: CustomFile[]) {
    return selectedFiles.every((file) => file.urlS3 && file.urlS3.trim() !== '')
  }

  function shortenFileName(filename) {
    const maxLength = 45
    const lastChars = 4

    const extensionIndex = filename?.lastIndexOf('.')

    if (extensionIndex === -1) return filename

    const extension = filename.slice(extensionIndex)
    const baseName = filename.slice(0, extensionIndex)

    if (baseName.length <= maxLength + lastChars) {
      return filename
    }

    const start = baseName.slice(0, maxLength)
    const end = baseName.slice(-lastChars)

    return `${start}...${end}${extension}`
  }

  const uploadFileToS3 = async (file: CustomFile) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      const { data, error }: PresignedUrl = await getPresignedUrl(appState.loggedInAs.token, file.name, file.type, file.size, `user:${appState.loggedInAs.uid}:public`)

      if (error) {
        return rej(error.message)
      }

      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            res(data.url)
          }
          else {
            rej('error - xhr !== 200')
          }
        }
      }
      xhr.upload.addEventListener('progress', (e) => {
        const progress = (e.loaded / e.total) * 100
      })

      xhr.addEventListener('error', () => rej('errors.response_not_ok'))
      xhr.addEventListener('abort', () => rej('errors.upload_aborted'))
      xhr.addEventListener('load', () => res(data.url))
      xhr.open('PUT', data.presignedUrl)
      Object.entries(data.headers).map((h) => {
        const [header, value] = h
        if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
        xhr.setRequestHeader(header, value)
      })
      xhr.send(file)
    })
  }

  async function uploadFileToGemini(appState, urls) {
    const baseUrl = `${BASE_API_URL}/api/v1/integrations/ai/magicCreator/uploadFiles`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({ urls }),
    }

    try {
      const response = await fetch(baseUrl, options)
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)

      const text = await response.text()

      return text ? JSON.parse(text) : {}

    } catch (error) {
      throw new Error('Error uploading file to Gemini')
    }
  }

  const handleFilePrecisionChange = (e, file: CustomFile) => {
    const updatedPrecision = e.target.value
    file.filePrecision = updatedPrecision
    setSelectedFiles(prevFiles => [...prevFiles])
    setMagicData((prevData) => ({
      ...prevData,
      uploadedFilesData: prevData.uploadedFilesData.map((fileData) => {
        if (fileData.displayName === file.geminiDisplayName) {
          return {
            ...fileData,
            filePrecision: updatedPrecision,
          }
        }
        return fileData
      }),
    }))
  }

  return ( <div className="relative mb-6 px-4 py-2 bg-[#F9F8F8] rounded-lg">
    <div className="flex flex-row items-center justify-start space-x-4 h-full">
      <div className="flex space-x-2">
        <label htmlFor="magicActionTitle" className={labelCn}>{intl.get('gnowbe_ai_uploaded_files')}</label>
        <AdditionalInfo tooltipContent={<div>{intl.getHTML('magic_upload_file_tooltip')}</div>} interactive />
      </div>
      <div data-test="attach-and-upload" className="flex space-x-2">
        <input 
          id="magic-ai-upload-input" ref={fileInputRef} type="file" 
          // accept="image/*,video/*,audio/*,.pdf" 
          accept="audio/wav,audio/mp3,audio/aiff,audio/aac,audio/ogg,audio/flac, 
          image/png,image/jpeg,image/webp,image/heic,image/heif,
          video/mp4,video/mpeg,video/mov,video/avi,video/x-flv,video/mpg,video/webm,video/wmv,video/3gpp,
          application/pdf,application/x-javascript,text/javascript,application/x-python,text/x-python,text/plain,text/html,text/css,text/md,text/csv,text/xml,text/rtf"
          className="hidden" onChange={handleFileChange}
        />
        <Button
          type="magic"
          className="bg-magic-light text-deepgray px-4 sm:px-6 py-2.5"
          text={intl.get('magic_attach')}
          onClick={(e) => {
            e.preventDefault()
            handleInputButtonClick()
            trackButtonEnhanced({
              button: `Gnowbe Ai ${typeOfCreator} Attach`,
              onScreen: `Magic ${typeOfCreator} Creator`,
            })
          }}
        />
         {(hasAudioOrVideoFiles && showUploadIndicator && !areAllFilesUploaded(selectedFiles)) && <div className="flex items-center">
          <p className="text-xs text-gray-500 mt-2">
            {intl.get('magic_upload_audio_video_note')}
          </p>
        </div>}
      </div>
    </div>
    {selectedFiles.map((file, index) => {
      return <div key={`${file}-${index}`} className="flex flex-row mt-3 space-x-2 sm:space-x-4 items-center justify-start">
        <div className="flex items-center w-full text-sm sm:text-base">
          <MagicSelectPrecision
            name="fileMatchingType"
            labelText={intl.get('gnowbe_ai_uploaded_files')}
            content={Object.entries(matchTypes)}
            hasInfo={true}
            tooltipContent={intl.get('magic_upload_file_tooltip')}
            onChange={(e) => handleFilePrecisionChange(e, file)}
            selectedValue={file.filePrecision || 'high_precision'}
          />
          <div onClick={() => {
            if (showUploadIndicator) return
            trackButtonEnhanced({
              button: `Gnowbe Ai ${typeOfCreator} Remove Uploaded File (X)`,
              onScreen: `Magic ${typeOfCreator} Creator`,
            })
            handleRemoveFile(index)
            }}>
            <XMarkIcon className="w-4 h-4 fill-[#c0c0c0] text-[#c0c0c0] cursor-pointer ml-4 mr-1" />
          </div>

          <div className="flex items-center space-x-2">
            <a href={file.urlS3 || '#'} target="_blank" rel="noopener noreferrer" className={`${file.urlS3 ? 'underline' : ''} text-magic`}>
              {shortenFileName(file.name)}
            </a>
            {showUploadIndicator && <MiniLoader />}
          </div>
        </div>
      </div>
    })}
  </div>
  )
}

export default MagicUploader