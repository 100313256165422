import React from "react"
import { wrapperInputCn, labelCn, inputAreaCn } from "../styles"
import { TypeOfCreator } from "../types"
import AdditionalInfo from "./AdditionalInfo"

const MagicTextArea = ({ typeOfCreator, magicData, handleInputChange, label, id, name, placeholder, additionalInfoText } : { typeOfCreator: TypeOfCreator, magicData: any, handleInputChange: (e) => void, label: string, id:string, name: string, placeholder: string, additionalInfoText?: string } ) => {
   return (
    <div className={wrapperInputCn}>
      <div className="flex flex-row space-x-2">
        <label htmlFor={id} className={labelCn}>{label}</label>
        {additionalInfoText && <AdditionalInfo tooltipContent={<div>{additionalInfoText}</div>} interactive />}
      </div>
      <textarea
        name={name}
        id={id}
        placeholder={placeholder}
        className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)] min-h-[130px]`}
        value={magicData || ''}
        onChange={(e) => handleInputChange(e)}
        maxLength={1000}
      />
      <div className='text-xs text-gray-500 text-right'>{magicData.length}/1000</div>
    </div>
  )
}

export default MagicTextArea