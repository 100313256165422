import React, { ChangeEvent, Dispatch, SetStateAction, memo, useEffect, useRef, useState } from 'react'
import Autocomplete from 'react-autocomplete'
import { produce } from 'immer'
import intl, { get } from 'react-intl-universal'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import { duplicateCourseMutation } from 'graphql/mutations/course/duplicateCourse'
import { checkHttpStatus, getAuthTokens } from 'actions/utils'
import { Alert, showAlert } from 'components/utils/Alert'
import { showModal } from 'components/utils/CustomModal'
import Toggle from 'components/utils/Toggle'
import { BASE_API_URL, CERTIFICATE_URL, COURSE_CATEGORIES, courseDifficulties, GNOWBE_SOURCE, APP_TITLE, BASE_DASHBOARD_URL, BASE_URL } from 'constants/General'
import { deleteCourseMutation } from 'graphql/mutations/course/deleteCourse'
import { Course, CourseOwner, ScormSettings } from 'graphql/schemas/course/Course'
import { SimpleEditable } from 'components/utils/SimpleEditable'
import { MD5, nl2br, getMeta, checkMeta } from 'utils/functions'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { sendCourseSizeReportMutation } from 'graphql/mutations/course/sendCourseSizeReport'
import { exportCourse, importCourse } from 'actions/editorial/courses'
import { updateCourseBulkMutation } from 'graphql/mutations/course/updateCourseBulk'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { inviteCuratorMutation } from 'graphql/mutations/course/inviteCurator'
import { generateMongoId } from 'utils/random'
import { client } from 'app'
import { DEFAULT_MIMETYPES, getPresignedUrl, getSignedValue, PresignedUrl, uploadFile } from 'actions/files'
import { uploadScormCourseContentMutation, UploadScormCourseContentRes, UploadScormCourseContentVars } from 'graphql/mutations/scorm/uploadScormCourseContent'
import { getUsersLinkedToOrganizationsQuery, GetUsersLinkedToOrganizationsRes, GetUsersLinkedToOrganizationsVars } from 'graphql/queries/organization/getUsersLinkedToOrganizations'
import { updateCourseMutation } from 'graphql/mutations/course/updateCourse'
import { GetCourseWithDataRes, GetCourseWithDataVars, getCourseWithDataQuery } from 'graphql/queries/course/getCourseWithData'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getUserAndActiveCourseQuery, GetUserAndActiveCourseResponse } from 'graphql/queries/user/getUser'
import { changeCurrentCourse, updateMeta } from 'actions/users_actions'
import { parseCourseKey } from 'models/utils'
import GnowbefyButton from 'components/navbar/buttons/GnowbefyButton'
import { Button } from 'components/utils/Button'
import { showDrawer } from 'components/utils/Drawer'
import PublishDialog from 'components/course/Publish'
import { getCourseEditorsQuery, GetCourseEditorsRes, GetCourseEditorsVars } from 'graphql/queries/course/getCourseEditors'
import { importPPTCourseMutation, ImportPPTCourseVars } from 'graphql/mutations/course/importPPTCourse'
import Lock from 'components/icons/Lock'
import { UpsellModal } from 'components/modals/UpsellModal'
import { publishCourse } from 'pages/Learn'
import { exportCourseContentQuery } from 'graphql/queries/editor/exportCourseContent'
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations'
import { useNavigate } from 'react-router-dom'
import { GetSupportedLanguagesForTranslationRes, getSupportedLanguagesForTranslationQuery } from 'graphql/queries/course/getSupportedLenguages'
import { duplicateAndTranslateCourseMutation } from 'graphql/mutations/course/translateCourse'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { GetGnowbeAiLimitPerOrganizationQuery, GetGnowbeAiLimitPerOrganizationRes, GetGnowbeAiLimitPerOrganizationVars } from 'graphql/queries/course/getTranslatorLimitPerOrganization'
import { GetCourseWordCountRes, GetCourseWordCountVars, getCourseWordCountQuery } from 'graphql/queries/course/getCourseWordCount'
import { isBillingAdminInWorkspace } from 'graphql/schemas/user/User'
import { NewTag } from 'components/navbar/Navbar'
import { SendUserLockedFeatureEmailVars, sendUserLockedFeatureEmailMutation } from 'graphql/mutations/emails/sendUserLockedFeatureEmail'
import MagicCreditsIcon from 'components/icons/MagicCredits'
import Lottie from 'lottie-react'
import magicCreditsAnimation from 'common/json/lottie/MagicCreditsAnimation.json'
import { importCourseQuestionsMutation, ImportCourseQuestionsVars } from 'graphql/mutations/course/importCourseQuestions'
import AssessmentCheckmarkIcon from 'components/icons/AssessmentCheckmarkIcon'
import MultiChoiceNoteIcon from 'components/icons/MultiChoiceNoteIcon'
import AdditionalInfo from 'components/gnowbeAi/components/AdditionalInfo'

type SidebarProps = {
  courseKey: string;
  menuExpanded: boolean;
  v2?: boolean;
}

const disclosureClassnames = 'collapsible-header text-xl font-bold text-deepgray w-full p-4 focus:outline-none focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-team relative'

export const Sidebar = ({ courseKey, menuExpanded, v2 }: SidebarProps) => {
  const navigate = useNavigate()

  const [companyId, courseId] = courseKey.split('-')

  const { data: appData } = useQuery<AppState>(getAppStateQuery)

  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  const { data: userData } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  const { data } = useQuery<GetCourseWithDataRes, GetCourseWithDataVars>(getCourseWithDataQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: true },
  })

  const [updateCourse] = useMutation(updateCourseMutation)

  const update = (field, value) => {
    if (field === 'title' && value === '') return

    updateCourse({
      variables: {
        courseId,
        courseUpdate: {
          [field]: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateCourse: {
          __typename: 'Course',
          ...data && data.course || {},
          courseId,
          [field]: value,
        },
      },
    })
  }

  if (!data || !appData || !userData || !subData) return <div>Loading...</div>

  const { course, chapters } = data
  const hasAssessmentActions = chapters && chapters.some(c => c.actions && c.actions.some(a => a.contentType === 'assessment'))

  const certificateUrl = course.curatorSignatureDisabled
    ? `${CERTIFICATE_URL}?curatorName=&curatorTitle=&curatorCompany=&courseTitle=${encodeURIComponent(course.title)}`
    : `${CERTIFICATE_URL}?curatorName=${encodeURIComponent(course.curatorName || '')}&curatorTitle=${encodeURIComponent(course.curatorTitle || '')}&curatorCompany=${encodeURIComponent(course.curatorCompany || '')}&courseTitle=${encodeURIComponent(course.title)}`

  const testCompany = course.testCompany
  const accessCodes = testCompany && testCompany.accessCodes
  const isEditor = subData.user.editorCourses.includes(courseId)
  const isActive = subData.user.profile.currentSubscription === `${companyId}-${courseId}`
  const isEditing = appData?.appState.isEditing && isActive && isEditor

  if (!menuExpanded) {
    return (
      <div className="pt-4 flex-1 flex items-start">
        <div className="flex justify-center flex-1">
          <div className="flex flex-col space-y-2">
            <Tippy placement="right" content={intl.get('program_edit_sessions')}>
              <button className="icon icon-session-light font-bold text-lg w-12 h-12" onClick={() => navigate('/learn')} />
            </Tippy>

            <Tippy placement="right" content={intl.get('share_text')}>
              <button className="icon icon-upload-light font-bold text-lg w-12 h-12" onClick={() => {
                trackButton({
                  button: 'publish_course',
                  on_screen: 'edit_session',
                })

                trackButtonEnhanced({
                  button: 'Share',
                  onScreen: 'Edit Session',
                })

                const drawer = showDrawer({
                  footer: { hide: true },
                  maxWidth: 'max-w-3xl',
                  component: <PublishDialog currentCompanyType={null} onClose={() => drawer?.current.close()} refetchQuery={() => null} />,
                  fromRight: true,
                })
              }} />
            </Tippy>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!v2 && (
        <>
          <div className="p-4 pb-0">
            <GnowbefyButton
              back={true}
              home={true}
              isEditing={true}
              courseId={courseId}
              currentSubscription={appData.appState.currentCompanyIdCourseId}
              urlCourseKey={parseCourseKey(courseKey)}
              email={subData.user.email}
              isPublisher={subData.user.roles.includes('publisher')}
              isIndependentEditor={subData.user.roles.includes('independentEditor')}
              onPublish={() => publishCourse(subData.user.metadata, subData.user.roles)}
              subData={subData}
              companyId={companyId}
            />
          </div>

          <div className="p-4 border-b border-gray-200 text-lg font-medium">
            <div className="text-deepgray">{intl.get('program_settings')}</div>
            <div className="text-sm font-normal mt-1 mb-4">{intl.get('set_program_settings')}</div>

            <div className="mt-4">
              <Button
                type="edit"
                text={intl.get('program_manage_sessions')}
                className="w-full border-2 text-base"
                onClick={() => {
                  trackButton({
                    button: 'edit_chapter_btn',
                    on_screen: 'edit_course',
                  })

                  trackButtonEnhanced({
                    button: 'Edit Session',
                    onScreen: 'Edit Program',
                  })

                  navigate('/learn')
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className={`${v2 ? 'border-t border-gray-200 mt-6' : undefined} flex-1 divide-y divide-gray-200 overflow-y-auto`}>
        {course.type === 'scorm' && (
          <ScormUpload
            courseId={course.id}
            scormSettings={course.scormSettings}
          />
        )}

        {!v2 && course.type !== 'scorm' && (
          <PptUploader2
            courseId={courseId}
            chapterCount={chapters.length}
            isImportingPpt={appData.appState.isImportingPpt}
            v2={false}
            upsell={!subData.user.features.includes('course-from_ppt')}
            onClose={() => null}
          />
        )}

        {course.type !== 'scorm' && (
          <LearningModePicker
            defaultOpen={true}
            chaptersUnlocked={course.chaptersUnlocked}
            update={update}
          />
        )}

        <Details
          v2={v2}
          course={course}
          update={update}
        />

        <TranslationButtons courseId={course.id} subData={subData} appData={appData} />

        {course.type !== 'scorm'
          ? <>
              <Graduation
                courseId={course.id}
                grade={course.graduationGrade}
                upsell={!subData.user.features.includes('action-certificate')}
                certificateUrl={certificateUrl}
                certSealUrl={course.certSealUrl}
                update={(v) => {
                  if (!isNaN(v)) {
                    update('graduationGrade', Number(v))
                  }
                  else {
                    showAlert(intl.get('error_field_number'), 'danger')
                  }
                }}>
              </Graduation>

              <AssessmentSettings
                courseId={course.id}
                isScorm={false}
                passingGrade={course.passingGrade.toString()}
                hasAssessmentActions={hasAssessmentActions}
                canRetakeActionAssessments={course.canRetakeActionAssessments}
                maxActionAssessmentsRetakes={course.maxActionAssessmentsRetakes}
                showActionAssessmentsResultsPerAction={course.showActionAssessmentsResultsPerAction}
                manualReviews={course.manualReviews}
                update={update}
                upsellAssessments={!subData.user.features.includes('action-assessment')}
                upsellManualReviews={!subData.user.features.includes('course-manualReviews')}
              />

              <ContentSettings
                workbookDisabled={course.workbookDisabled}
                allowMediaDownload={course.allowMediaDownload}
                denyContentCopy={course.denyContentCopy}
                owner={course.owner}
                update={update}
              />

              <ChangeOwner currentOwner={course.owner} />
            </>
          : course.type !== 'scorm'
            ? <section className="p-4">
                <ChangeOwner alt={true} currentOwner={course.owner} />
              </section>
            : null
        }

        <AdditionalButtons
          appData={appData}
          subData={subData}
          userEmail={subData.user.email}
          courseId={course.id}
          title={course.title}
          subsCount={subData.user.subscriptions.length}
          isScorm={course.type === 'scorm'}
        />
      </div>

      <EditButtons
        courseId={course.id}
        email={subData.user.email}
      />

      {/*
      <div className="page">
        <h3>Next steps</h3>
        <ul className="mb-4">
          <li className="line-through text-gray-500">Create a program</li>
          <li><a href="#">Update the program</a></li>
          <li><a href="#">Update the first session</a></li>
          <li><a href="#">Create the first action</a></li>
        </ul>
      </div>
      */}
    </>
  )
}

type GraduationProps = {
  courseId: string;
  grade: number;
  certificateUrl: string;
  certSealUrl: string|null;
  children?: any;
  upsell: boolean;
  update: (e: number) => void;
}

const Graduation = ({ courseId, grade, certificateUrl, certSealUrl, upsell, children, update }: GraduationProps) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)

  let certUrl = certificateUrl
  if (certSealUrl) {
    const signedCertSeal = getSignedValue(certSealUrl)
    certUrl += `&courseCertSealUrl=${signedCertSeal}`
  }

  const tippyContent = upsell
    ? intl.get('upsell_paid_plans_dialog')
    : 'This represents the lowest percentage a learner must complete in order to receive the certificate. If set to 0% there will be no minimum threshold.'

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames} onClick={(e) => {
            if (upsell && !open) {
              const modal = showModal({
                title: intl.get('home_group_invite'),
                component: <UpsellModal type="upgrade_for_certificate" track="certificate" close={() => modal.close()} />,
                onlyContent: true,
                className: 'add-action-modal',
              })
              sendUserLockedFeatureEmail({
                variables: {
                  feature: 'feature-locked-add-digital-certificates',
                  organizationId: activeWorkspaceId
                 },
              })
              return
            }
          }}>
            <Tippy placement="right" content={intl.get('upsell_paid_plans_dialog')} disabled={!upsell}>
              <h3 className="font-noto-sans text-xl font-bold text-deepgray flex justify-between items-center">
                {intl.get('global_certificate')}

                {upsell && <i className="icon icon-lock-solid text-base inline-block ml-2" />}
              </h3>
            </Tippy>

            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8">
              <div className="flex gap-4 items-center">
                <div className={`${upsell ? 'text-gray-300' : ''}`}>
                  {intl.get('course_issue_certificate_at')}
                </div>

                <SimpleEditable
                  key={`${courseId}-graduationGrade`}
                  defaultValue={grade.toString()}
                  placeholder="80"
                  isEditing={true}
                  append="%"
                  classname="w-24"
                  tooltip={tippyContent}
                  disabled={upsell}
                  update={v => update(Number(v))}
                />
              </div>

              <div className="mt-1 text-gray-600">{intl.get('course_certificate_completion_rate_required_description')}</div>

              <UploadCertificateSeal
                courseId={courseId}
                certSealUrl={certSealUrl}
                upsell={upsell}
              />

              {children}

              <Button
                className="mt-4 mb-6"
                // rightIcon={upsell ? 'icon-lock-solid' : undefined}
                text={intl.get('course_preview_certificate')}
                tooltip={upsell ? intl.get('upsell_paid_plans_dialog') : undefined}
                onClick={() => {
                  if (upsell) {
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type="starter" track="certificate" close={() => modal.close()} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                    })
                    return
                  }
                  window.open(certUrl)
                }}
              />
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

export const DifficultyPicker = ({ difficulty, onChange }: { difficulty: string, onChange: (val: string) => void }) => {
  return (
    <div className="form-group">
      <label htmlFor="program_difficulty" className="block font-medium leading-5">{intl.get('course_difficulty')}</label>

      <select
        id="program_difficulty"
        className="border-team mt-2 block w-full py-2 px-3 border bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300"
        name="difficulty"
        value={difficulty}
        onChange={e => onChange(e.target.value)}>
        {courseDifficulties.map((diff) => {
          return <option key={diff.id} value={diff.id}>{diff.title}</option>
        })}
      </select>
    </div>
  )
}

export const SkillsPicker = ({ skills, onUpdate }: { skills: string[], onUpdate: (val: string[], insta: boolean) => void }) => {
  const [skill, setSkill] = useState('')
  const [suggestions, setSuggestions] = useState(new Set<string>())
  const _timeout = useRef<any>(null)

  const autocomplete = (text: string) => {
    setSkill(text)

    _timeout.current = clearTimeout(_timeout.current)
    _timeout.current = setTimeout(() => {
      const token = getAuthTokens()
      fetch(`${BASE_API_URL}/api/v1/editor/course/skills_autocomplete?text=${text}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-gnowbe-source': GNOWBE_SOURCE,
        },
      })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (!response.error) {
          setSuggestions(new Set(response.data.skills))
        }
      })
    }, 500)
  }

  const updateSkills = (s: string, op: string) => {
    let newSkills: Set<string> = new Set(skills)
    if (op === 'add') {
      newSkills = newSkills.add(s)
    }
    else {
      newSkills.delete(s)
    }
    setSkill('')
    onUpdate(Array.from(newSkills), true)
  }

  return (
    <div className="form-group">
      <label htmlFor="program_skills" className="block font-medium leading-5">{intl.get('course_skills')}</label>

      <div className="relative rounded-md shadow-sm mt-2">
        <Autocomplete
          getItemValue={item => item}
          inputProps={{
            id: 'program_skills',
            type: 'text',
            className: 'border border-team block w-full focus:ring-blue-300 focus:border-blue-300 rounded-md',
            placeholder: intl.get('type_a_skill'),
            onKeyPress: (e) => {
              if (e.key === 'Enter') {
                updateSkills(e.target.value, 'add')
              }
            },
          }}
          menuStyle={{ maxHeight: 200, overflow: 'auto', position: 'absolute', top: '2.45rem', left: 0, zIndex: 20, borderRadius: 4, border: 'solid 1px #feb638', fontSize: '.95rem' }}
          wrapperStyle={{ display: 'block' }}
          items={Array.from(suggestions)}
          renderItem={(item, isHighlighted) =>
            <div key={item} className={`text-md px-3 pt-2 pb-1 cursor-pointer ${isHighlighted ? 'bg-actions-quote' : 'bg-white'}`}>
              {item}
            </div>
          }
          value={skill}
          onChange={e => autocomplete(e.target.value)}
          onSelect={val => updateSkills(val, 'add')}
        />
      </div>


      {skills.length > 0 &&
        <div className="mt-3 flex flex-wrap">
          {skills.map((skill) => {
            return (
              <span key={skill} className="mb-2 mr-2 inline-flex items-center px-2.5 py-1.5 rounded-md font-medium leading-4 bg-[#F7DD79] text-deepgray">
                <button
                  className="shrink-0 mr-1.5 inline-flex text-gray-500 focus:outline-none hover:text-gray-700"
                  onClick={() => updateSkills(skill, 'del')}>
                  <svg className="h-3 w-3" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>

                {skill}
              </span>
            )
          })}
        </div>
      }
    </div>
  )
}

export const LanguagePicker = memo(({ language, onChange }: { language: string, onChange: (s: string) => void }) => {
  return (
    <div className="form-group">
      <label htmlFor="program_language" className="block font-medium leading-5">{intl.get('course_language')}</label>

      <select
        id="program_language"
        className="border-team mt-2 block w-full border bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 editable"
        data-placeholder="Choose a Language..."
        value={language.toUpperCase()}
        onChange={(e) => {
          onChange(e.target['value'])
        }}>
        <option value="ID">Bahasa Indonesia</option>
        <option value="DA">Dansk</option>
        <option value="DE">Deutsch</option>
        <option value="EN">English</option>
        <option value="ES">Español</option>
        <option value="IT">Italiano</option>
        <option value="NL">Nederlands</option>
        <option value="PT">Portuguese</option>
        <option value="SL">Slovenščina</option>
        <option value="MY">မြန်မာစာ</option>
        <option value="KO">한국어</option>
        <option value="JA">日本語</option>
        <option value="ZH">简体中文</option>
        <option disabled>--</option>

        <option value="AF">Afrikanns</option>
        <option value="SQ">Albanian</option>
        <option value="AR">Arabic</option>
        <option value="HY">Armenian</option>
        <option value="EU">Basque</option>
        <option value="BN">Bengali</option>
        <option value="BG">Bulgarian</option>
        <option value="CA">Catalan</option>
        <option value="KM">Cambodian</option>
        <option value="HR">Croation</option>
        <option value="CS">Czech</option>
        <option value="DA">Danish</option>
        <option value="ET">Estonian</option>
        <option value="FJ">Fiji</option>
        <option value="FI">Finnish</option>
        <option value="FR">French</option>
        <option value="KA">Georgian</option>
        <option value="EL">Greek</option>
        <option value="GU">Gujarati</option>
        <option value="HE">Hebrew</option>
        <option value="HI">Hindi</option>
        <option value="HU">Hungarian</option>
        <option value="IS">Icelandic</option>
        <option value="ID">Indonesian</option>
        <option value="GA">Irish</option>
        <option value="IT">Italian</option>
        <option value="JW">Javanese</option>
        <option value="LA">Latin</option>
        <option value="LV">Latvian</option>
        <option value="LT">Lithuanian</option>
        <option value="MK">Macedonian</option>
        <option value="MS">Malay</option>
        <option value="ML">Malayalam</option>
        <option value="MT">Maltese</option>
        <option value="MI">Maori</option>
        <option value="MR">Marathi</option>
        <option value="MN">Mongolian</option>
        <option value="NE">Nepali</option>
        <option value="NO">Norwegian</option>
        <option value="FA">Persian</option>
        <option value="PL">Polish</option>
        <option value="PT">Portuguese</option>
        <option value="PA">Punjabi</option>
        <option value="QU">Quechua</option>
        <option value="RO">Romanian</option>
        <option value="RU">Russian</option>
        <option value="SM">Samoan</option>
        <option value="SR">Serbian</option>
        <option value="SK">Slovak</option>
        <option value="SL">Slovenian</option>
        <option value="ES">Spanish</option>
        <option value="SW">Swahili</option>
        <option value="SV">Swedish </option>
        <option value="TA">Tamil</option>
        <option value="TT">Tatar</option>
        <option value="TE">Telugu</option>
        <option value="TH">Thai</option>
        <option value="BO">Tibetan</option>
        <option value="TO">Tonga</option>
        <option value="TR">Turkish</option>
        <option value="UK">Ukranian</option>
        <option value="UR">Urdu</option>
        <option value="UZ">Uzbek</option>
        <option value="VI">Vietnamese</option>
        <option value="CY">Welsh</option>
        <option value="XH">Xhosa</option>
      </select>
    </div>
  )
})

export const EnglishTitleField = memo(({ courseId, englishTitle, onChange }: { courseId: string, englishTitle: string, onChange: any }) => {
  return (
    <div className="form-group">
      <label className="block font-medium leading-5">{intl.get('course_english_title')}</label>

      <SimpleEditable
        key={`${courseId}-english-title`}
        defaultValue={englishTitle}
        placeholder="English Title"
        isEditing={true}
        fullWidth={true}
        style={{ marginTop: 10 }}
        update={v => onChange(v)}
      />
    </div>
  )
})

interface CategoryPickerProps {
  defaultCategory: string;
  defaultCategoryText: string;
  onChange: (val: string) => void;
  onSelect: (val: string, insta: boolean) => void;
}

export const CategoryPicker = ({ defaultCategory, defaultCategoryText, onChange, onSelect }: CategoryPickerProps) => {
  const [category, setCategory] = useState(defaultCategory || '')
  const [categoryText, setCategoryText] = useState(defaultCategoryText || '')
  const [categories, setCategories] = useState<string[]>([])
  const timeout: any = useRef(null)

  const onSelectChange = (e) => {
    const value = e.target['value']
    setCategory(value)
    onChange(value)
  }

  const onTextSelect = (val, insta) => {
    setCategoryText(val)
    if (!val) {
      showAlert('The category text can\'t be empty.', 'danger')
      return
    }
    onSelect(val, insta)
  }

  const autocomplete = (text: string) => {
    setCategoryText(text)

    timeout.current = clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      fetch(`${BASE_API_URL}/api/v1/editor/course/custom_category_autocomplete?text=${text}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'x-gnowbe-source': GNOWBE_SOURCE,
        },
      })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (!response.error) {
          const cats: string[] = Array.from(new Set<string>(response.data.customCategories).add(text))
          setCategories(cats)
        }
        else {
          //
        }
      })
    }, 250)
  }

  const currentCategory = category && COURSE_CATEGORIES.find(c => c.id === category)

  return (
    <div className="form-group">
      <label htmlFor="course_categories" className="block font-medium leading-5">{intl.get('course_category')}</label>

      <select
        id="course_categories"
        className="border-team mt-2 block w-full py-2 px-3 border bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 editable"
        name="category"
        value={category}
        onChange={onSelectChange}>
        {COURSE_CATEGORIES.filter(c => c.id !== 'notSet').map((cat) => {
          return <option key={cat.id} value={cat.id}>{cat.title}</option>
        })}
      </select>

      {category !== 'CS' && currentCategory && currentCategory.description &&
        <div className="px-4 py-3 mt-4 bg-lightwarmgray text-gray-700 text-sm leading-5">
          {currentCategory.description}
        </div>
      }

      {category === 'CS' &&
        <div className="mt-4">
          <label htmlFor="category_text" className="block font-medium leading-5">{intl.get('course_category_text')}</label>
          <Autocomplete
            getItemValue={item => item}
            inputProps={{
              id: 'category_text',
              type: 'text',
              className: 'border border-team block w-full focus:ring-blue-300 focus:border-blue-300 rounded-md',
              onKeyPress: (e) => {
                if (e.key === 'Enter') {
                  onTextSelect(e.target.value, true)
                }
              },
            }}
            wrapperStyle={{ display: 'block', position: 'relative' }}
            menuStyle={{ maxHeight: 300, overflow: 'auto', position: 'absolute', top: '2.4rem', left: 0, zIndex: 1, borderRadius: 4, border: 'solid 1px #feb638', fontSize: '.95rem' }}
            items={categories} // check here
            renderItem={(item, isHighlighted) =>
              <div key={`${generateMongoId()}`} className={`text-md px-3 pt-2 pb-1 cursor-pointer ${isHighlighted ? 'bg-actions-quote' : 'bg-white'}`}>
                {item}
              </div>
            }
            value={categoryText}
            onChange={e => autocomplete(e.target.value)}
            onSelect={val => onTextSelect(val, true)}
          />
        </div>
      }
    </div>
  )
}

const DeleteCourseButton = ({ courseId, title, subsCount, sandboxWorkspaceId, activeWorkspaceId }) => {
  const navigate = useNavigate()
  const confirmRemoval = (del) => {
    if (subsCount > 1) {
      const translatedDelete = intl.get('delete').toUpperCase()
      showModal({
        title: intl.get('course_edit_modal_remove_program_title'),
        content: intl.get('course_remove_text', { 0: title, 1: APP_TITLE[process.env.BUILD || 'production'] }),
        primaryText: intl.get('course_delete'),
        destructive: true,
        primaryAction: () => {
          const cnf = prompt(intl.get('course_remove_input_popup_message', { 0:  intl.get('delete').toUpperCase() }))
          if (cnf === translatedDelete) {
            navigate('/home')
            changeCurrentCourse({ courseId, courseKey: undefined, landing: '/home', enableEditing: false })
            del({
              variables: {
                courseId,
              },
            })
          }
          else {
            if (!cnf) {
              alert('You cancelled so we are NOT removing the program.')
            }
            else {
              alert(`You wrote ${cnf} so we are NOT removing the program.`)
            }
          }
        },
        secondaryText: intl.get('cancel'),
      })
    }
    else {
      showModal({
        title: intl.get('error_dialog_title'),
        content: intl.get('course_remove_error'),
        primaryText: intl.get('ok'),
        secondaryButton: false,
      })
    }
  }

  const [deleteCourse, { data }] = useMutation(deleteCourseMutation, {
    onCompleted: () => {
      showAlert(intl.get('course_successfully_removed'), 'success')
    },
  })

  return (
    <Button
      type="danger"
      text={intl.get('course_delete')}
      onClick={() => confirmRemoval(deleteCourse)}
      disabled={sandboxWorkspaceId !== activeWorkspaceId}
    />
  )
}

const DuplicateCourseButton = memo(({ courseId, isScorm }: { courseId: string, isScorm: boolean }) => {
  const [duplicateCourse] = useMutation(duplicateCourseMutation)
  const [isDuplicating, setIsDuplicating] = useState(false)

  return (
    <>
      <Button
        text={isDuplicating ? 'Duplicating...' : intl.get('course_duplicate')}
        disabled={isDuplicating || isScorm}
        onClick={(e) => {
          e.preventDefault()
          setIsDuplicating(true)

          const data = client.readQuery({ query: getAppStateQuery })
          const newData = produce(data, (draft) => {
            draft.appState.isDuplicatingCourse = true
            draft.appState.isCreatingCourse = true
            draft.appState.isLoading = true
          })
          client.writeQuery({
            data: newData,
            query: getAppStateQuery,
          })

          duplicateCourse({
            variables: {
              courseId,
              courseDuplicate: {},
            },
          })
        }}
      />

      {isScorm && (
        <div className="bg-yellow-100 p-4 rounded-md mt-4 text-sm text-yellow-900 flex items-center">
          <i className="icon icon-info text-yellow-900 mr-3" /> {intl.get('course_duplicate_not_available')}
        </div>
      )}
    </>
  )
})

const TranslateButtonModal = ({ courseId ,closeModal, setIsTranslating, refetch, count, remainingCredits, loadingGnowbeAiEstimation }: {courseId: string, closeModal: () => void, setIsTranslating: Dispatch<SetStateAction<boolean>>, refetch: () => void, count: number, remainingCredits: number, loadingGnowbeAiEstimation: boolean}) => {
  const [translateCourse] = useMutation(duplicateAndTranslateCourseMutation)
  const { data: supportedLanguages } = useQuery<GetSupportedLanguagesForTranslationRes>(getSupportedLanguagesForTranslationQuery)
  const {data: courseWordCount, refetch: refetchCourseWordCount } = useQuery<GetCourseWordCountRes, GetCourseWordCountVars>(getCourseWordCountQuery, {
    variables: { courseId },
  })
  const activeWorkspaceId = useActiveWorkspaceId()
  const [colorOfTheUsage, setColorOfTheUsage] = useState('text-lightgray')
  const [selectedLenguage, setSelectedLanguage] = useState('')
  const wordCount = courseWordCount?.res?.wordCount || 0
  const creditsCount = courseWordCount?.res?.creditsCount || 0
  const isBillingAdmin = isBillingAdminInWorkspace()

  const handleChange = (e) => {
    const value = e.target.value
    setSelectedLanguage(value)
  }

  const percentage = count / remainingCredits * 100

  useEffect(() => {
    if (percentage >= 80) {
      setColorOfTheUsage('coral')
    }
    else if (percentage >= 60) {
      setColorOfTheUsage('team')
    } else {
      setColorOfTheUsage('lake')
    }
  }, [])

  useEffect(() => {
    refetchCourseWordCount()
  }, [courseId])

  const redirectToDashboard = () => {
    window.open(`${BASE_DASHBOARD_URL}/organization/${activeWorkspaceId}?scrollTo=settings-billing-details&addon=true#settings`, '_blank')
    closeModal()
  }

  const languagesArray = supportedLanguages?.supportedLanguages ?? [{name: '', code: '' }]
  const sortedLanguagesByName = [...languagesArray].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div className="flex flex-col relative">
      {/* hardcoded limit for not CU plans is 10000 */}
      <div className={`absolute bottom-0 left-0 border-1 border-gray-500 text-medgray py-1 px-2 rounded-xl`}>
        <Tippy content="Words translated" disabled={remainingCredits <= 10000}>
          <div data-test="ai-credits" className="flex flex-row items-center justify-center space-x-1 sm:space-x-4">
            {loadingGnowbeAiEstimation ? <Lottie className="w-10 h-10" animationData={magicCreditsAnimation}/> : <MagicCreditsIcon className="w-10 h-10 fill-magic" />}
            <div className={loadingGnowbeAiEstimation ? 'blur-sm animate-pulse': ''}>
              <div data-test="credit-count" className="font-bold text-xs">{creditsCount} {intl.get('settings_credits')}</div>
              <div data-test="available-credits" className="flex items-center justify-center space-x-2 text-xs">
                <div>
                  {remainingCredits} {intl.get('global_available')}
                </div>
                <AdditionalInfo
                  tooltipContent={<div>{intl.getHTML('magic_credits_tooltip')}</div>}
                  interactive={true} />
              </div>
            </div>
          </div>
        </Tippy>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="text-2xl font-medium mb-4 justify-center items-center">
          <img src="/images/wand-magic-sparkles.svg" className="w-8 h-8 mr-4 inline-block" />
          {intl.get('gnowbe_ai_translator')}
        </div>
        <div className="text-sm text-medgray">
          {intl.get('select_language_from_list_title')}
          <div className="mt-1">
            You're about to translate <strong>{courseWordCount?.res?.wordCount || 0}</strong> words,
            which will require <strong>{courseWordCount?.res?.creditsCount || 0}</strong> credits.
          {/* <div className="mt-1 text-xs">{intl.get('translation_modal_disclaimer')}</div> */}
          {creditsCount > remainingCredits ? (
            <>
              {' '}With that, you would exceed the limit of the addon.{' '}
              {isBillingAdmin ? (
                <span>
                  <button
                    className="text-coral underline hover:cursor-pointer"
                    onClick={() => redirectToDashboard()}
                  >
                    Upgrade now
                  </button>{' '}
                  to increase the addon quantity.
                </span>
              ) : (
                'Please contact your Billing Admin to increase the addon quantity.'
              )}
            </>
          ) : percentage >= 80 && (
            <>
              {' '}You've reached {percentage}% of your words limit.{' '}
              {isBillingAdmin ? (
                <span>
                  <button
                    className="text-coral underline hover:cursor-pointer"
                    onClick={() => redirectToDashboard()}
                  >
                    Upgrade now
                  </button>{' '}
                  to increase the addon quantity.
                </span>
              ) : (
                'Please contact your Billing Admin to increase the addon quantity.'
              )}
            </>
          )}
          </div>
        </div>
        <select
          value={selectedLenguage}
          className="w-full my-6 block border rounded-md shadow-sm focus:outline-none focus:ring-magic focus:border-magic border-magic sm:text-sm editable"
          data-test="select-language"
          onChange={handleChange}
          >
          {selectedLenguage === '' && <option className={`text-lightgray hidden`} value="">{intl.get('select_a_language')}</option>}
          {sortedLanguagesByName.map((lenguage, index) => {
            return <option className="relative" key={index} value={lenguage.code}>{lenguage.name}</option>
          })}
        </select>
      </div>
      <div className="flex flex-row space-x-2 justify-end items-end mt-4">
        <Button text={intl.get('global_cancel')} onClick={() => closeModal()} />
        {(creditsCount > remainingCredits && isBillingAdmin)
          ? <Button type="danger" text="Upgrade Now" onClick={() => redirectToDashboard()} />
          : <Button text={intl.get('duplicate_and_translate')} type="magic" className='btn-v2 magic' disabled={selectedLenguage === '' || creditsCount > remainingCredits} onClick={() => {
          setIsTranslating(true)
          const data = client.readQuery({ query: getAppStateQuery })
          const newData = produce(data, (draft) => {
            draft.appState.isDuplicatingCourse = true
            draft.appState.isCreatingCourse = true
            draft.appState.isLoading = true
          })
          client.writeQuery({
            data: newData,
            query: getAppStateQuery,
          })

          translateCourse({
            variables: {
              courseId,
              toLanguage: selectedLenguage,
              courseDuplicate: {},
              organizationId: activeWorkspaceId,
            },
            onCompleted: () => {
              track({
                event: 'Program Translated',
                variables: {
                  programId: courseId,
                  toLanguage: selectedLenguage,
                },
              })
              refetch()
            },
          })
          closeModal()
          }}/>
        }
      </div>
    </div>
  )
}

const TranslateCourseButton = memo(({ courseId, disabled, metadata }: { courseId: string, disabled?: boolean, metadata: any }) => {
  const activeWorkspaceId = useActiveWorkspaceId()

  const { data, loading, error, refetch } = useQuery<GetGnowbeAiLimitPerOrganizationRes, GetGnowbeAiLimitPerOrganizationVars>(GetGnowbeAiLimitPerOrganizationQuery, {
    variables: { organizationId: activeWorkspaceId },
  })
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)
  const [isTranslating, setIsTranslating] = useState(false)
  const [showNewDot, setshowNewDot] = useState(!checkMeta(metadata, '/general/translationButtonSeen'))

  const count = data?.res.count || 0
  const remainingCredits = data?.res.remainingCredits || 0

  useEffect(() => {
    if (checkMeta(metadata, '/general/translationButtonSeen')) {
      return
    }

    updateMeta('translationButtonSeen', 'true', true)
    setTimeout(() => {
      setshowNewDot(false)
    }, 1500)

  }, [])

  return (
    <div>
      <Button
        text={isTranslating ? intl.get('translating') : intl.get('gnowbe_ai_translator')}
        // disabled={isTranslating || disabled}
        disabled={isTranslating} // if is disable, don't block, show upsell modal???
        icon={disabled ? 'icon-lock-solid' : undefined}
        fullWidth={true}
        onClick={(e) => {
          e.preventDefault()
          track({
            event: 'Button Translate Program Clicked',
            variables: {
              programId: courseId,
            },
          })
          if (disabled) {
            sendUserLockedFeatureEmail({
              variables: {
                feature: 'feature-locked-automatic-language-translations',
                organizationId: activeWorkspaceId
              },
            })
          }
          const modal = !disabled
          ? showModal({
            component: <TranslateButtonModal key={Date.now()} courseId={courseId} closeModal={() => modal.close()} setIsTranslating={setIsTranslating} refetch={refetch} count={count} remainingCredits={remainingCredits} loadingGnowbeAiEstimation={loading} />,
            primaryText: intl.get('duplicate_and_translate'),
            onlyContent: true,
          })
          : showModal({
            title: intl.get('home_group_invite'),
            component: <UpsellModal type="upgrade_for_translate_program" track="translate_program" close={() => {
              modal.close()
            }} />,
            onlyContent: true,
            className: 'add-action-modal',
          })
        }}
        showNewDot={showNewDot}
      />
    </div>
  )
})

const ExportCourseContent = memo(({ courseId, email }: { courseId: string, email: string }) => {
  const _deb = useRef<any>(null)
  const [exportCourseContent] = useLazyQuery(exportCourseContentQuery, {
    variables: { courseId },
    onCompleted: (data) => {
      _deb.current = clearTimeout(_deb.current)
      _deb.current = setTimeout(() => {
        setIsExporting(false)
        showModal({
          title: intl.get('exporting'),
          component: <div>The exported content will be sent to your email (<span className="font-bold">{data.exportCourseContent.emailTo}</span>) as soon as it is ready.</div>,
          secondaryButton: false,
        })
      }, 1000)
    },
  })
  const [isExporting, setIsExporting] = useState(false)

  return (
    <Button
      text={isExporting ? intl.get('exporting') : intl.get('export_program_content')}
      disabled={isExporting}
      onClick={() => {
        setIsExporting(true)
        exportCourseContent()
      }}
    />
  )
})

const AssessmentSettings = ({
  courseId,
  passingGrade,
  hasAssessmentActions,
  canRetakeActionAssessments,
  maxActionAssessmentsRetakes,
  showActionAssessmentsResultsPerAction,
  manualReviews,
  isScorm,
  upsellAssessments,
  upsellManualReviews,
  update,
}) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)


  const upsellTip = upsellAssessments
    ? intl.get('upsell_paid_plans_dialog')
    : hasAssessmentActions
      ? upsellManualReviews
        ? intl.get('upsell_assessments_settings')
        : 'Can\'t enable Assessments Pro because you have assessment actions in your program.'
      : ''

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div>
            <DisclosureButton className={disclosureClassnames} onClick={(e) => {
              if (upsellAssessments && !open) {
                const modal = showModal({
                  title: intl.get('home_group_invite'),
                  component: <UpsellModal type="upgrade_for_assessment_basic" track="assessment_mode_basic" close={() => modal.close()} />,
                  onlyContent: true,
                  className: 'add-action-modal',
                })
                sendUserLockedFeatureEmail({
                  variables: {
                    feature: 'feature-locked-assessment-basic',
                    organizationId: activeWorkspaceId
                   },
                })
                return
              }
            }}>
              <Tippy placement="right" content={intl.get('upsell_paid_plans_dialog')} disabled={!upsellAssessments}>
                <h3 className="font-noto-sans text-xl font-bold text-deepgray flex justify-between items-center">
                  {intl.get('course_assessments_basic')}

                  {upsellAssessments && <i className="icon icon-lock-solid text-base inline-block ml-2" />}
                </h3>
              </Tippy>
              <ChevronUpIcon
                className={`${
                  open ? 'rotate-180' : ''
                } w-5 h-5 text-gray-500`}
              />
            </DisclosureButton>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0">
              <DisclosurePanel className="pb-6 px-8">
                <>
                  <Tippy placement="right" content={intl.get('course_tooltip_passing_grade')} disabled={upsellAssessments}>
                    <div className="flex items-center">
                      <div className={`mr-4 font-bold ${upsellAssessments ? 'text-gray-300' : ''}`}>{intl.get('course_passing_grade')}</div>

                      <SimpleEditable
                        key={`${courseId}-passingGrade`}
                        type="number"
                        defaultValue={passingGrade}
                        placeholder={intl.get('course_passing_grade')}
                        isEditing={true}
                        classname="w-24"
                        tooltip={upsellAssessments ? intl.get('upsell_paid_plans_dialog') : undefined}
                        maxLength={3}
                        append="%"
                        disabled={upsellAssessments}
                        update={(v) => {
                          if (!isNaN(v)) {
                            update('passingGrade', Number(v))
                          }
                          else {
                            showAlert(intl.get('error_field_number'), 'danger')
                          }
                        }}
                      />
                    </div>
                  </Tippy>

                  <div className="mt-1 text-gray-600">{intl.get('course_passing_grade_description')}</div>

                  {!isScorm && (
                    <div className="my-4">
                      <Toggle
                        id="showActionAssessmentsResultsPerAction"
                        defaultChecked={showActionAssessmentsResultsPerAction}
                        label={intl.get('course_show_assessment_results')}
                        disabled={manualReviews || upsellAssessments}
                        onChange={e => update('showActionAssessmentsResultsPerAction', !showActionAssessmentsResultsPerAction)}
                      />

                      <div className="text-gray-600">{intl.get('course_show_assessment_results_description')}</div>
                    </div>
                  )}

                  <div>
                    <Toggle
                      id="canRetakeActionAssessments"
                      defaultChecked={canRetakeActionAssessments}
                      label={intl.get('course_retake_assessments')}
                      disabled={manualReviews || upsellAssessments}
                      onChange={e => update('canRetakeActionAssessments', !canRetakeActionAssessments)}
                      tooltip="Enabling this option will allow learners to retake assessments by removing their answers and resetting their score."
                    />

                    <div className="text-gray-600">{intl.get('course_retake_assessments_description')}</div>
                  </div>

                  {canRetakeActionAssessments && (
                    <Toggle
                      id="allowUnlimitedRetakes"
                      defaultChecked={maxActionAssessmentsRetakes === 99999}
                      label={intl.get('course_unlimited_attempts')}
                      disabled={manualReviews || upsellAssessments}
                      onChange={e => update('maxActionAssessmentsRetakes', e && 99999 || 5)}
                    />
                  )}

                  {canRetakeActionAssessments && maxActionAssessmentsRetakes !== 99999 && (
                    <Tippy placement="right" content={intl.get('course_tooltip_max_assessment_retakes')} disabled={upsellAssessments}>
                      <div className="flex items-center">
                        <div className="mr-4">{intl.get('course_max_assessment_attempts')}</div>
                        <SimpleEditable
                          key={`${courseId}-maxActionAssessmentsRetakes`}
                          type="number"
                          defaultValue={maxActionAssessmentsRetakes}
                          placeholder={intl.get('course_max_assessment_attempts')}
                          isEditing={true}
                          style={{ width: 70 }}
                          maxLength={3}
                          disabled={upsellAssessments}
                          update={(v) => {
                            if (!isNaN(v)) {
                              update('maxActionAssessmentsRetakes', Number(v))
                            }
                            else {
                              showAlert(intl.get('error_field_number'), 'danger')
                            }
                          }}
                        />
                      </div>
                    </Tippy>
                  )}
                </>

              </DisclosurePanel>
            </Transition>
          </div>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <div>
            <DisclosureButton className={disclosureClassnames} onClick={(e) => {
              if (upsellManualReviews && !open) {
                const modal = showModal({
                  title: intl.get('home_group_invite'),
                  component: <UpsellModal type="upgrade_for_assessment_pro" track="assessment_mode_basic" close={() => modal.close()} />,
                  onlyContent: true,
                  className: 'add-action-modal',
                })
                sendUserLockedFeatureEmail({
                  variables: {
                    feature: 'feature-locked-assessment-pro',
                    organizationId: activeWorkspaceId
                   },
                })
                return
              }
            }}>
              <Tippy placement="right" content={intl.get('upsell_paid_plans_dialog')} disabled={!upsellManualReviews}>
                <h3 className="font-noto-sans text-xl font-bold text-deepgray flex justify-between items-center">
                  {intl.get('course_assessments_pro')}

                  {upsellManualReviews && <i className="icon icon-lock-solid text-base inline-block ml-2" />}
                </h3>
              </Tippy>
              <ChevronUpIcon
                className={`${
                  open ? 'rotate-180' : ''
                } w-5 h-5 text-gray-500`}
              />
            </DisclosureButton>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0">
              <DisclosurePanel className="pb-6 px-8">
                <>
                  <Tippy placement="right" content={upsellTip} disabled={!upsellTip}>
                    <div>
                      <Toggle
                        id="manualReviews"
                        defaultChecked={manualReviews}
                        label={intl.get('manual_reviews_setting')}
                        disabled={hasAssessmentActions || upsellManualReviews}
                        onChange={e => update('manualReviews', !manualReviews)}
                      />
                    </div>
                  </Tippy>

                  <div className="text-gray-600">{intl.get('manual_reviews_setting_description')}</div>
                </>
              </DisclosurePanel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  )
}

const SendCourseSizeReport = ({ courseId }) => {
  const [sendCourseSizeReport, { data }] = useMutation(sendCourseSizeReportMutation, { variables: { courseId }})
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (data) {
      showModal({
        title: intl.get('ok'),
        content: data.sendCourseSizeReport,
        secondaryButton: false,
      })
      setIsSending(false)
    }
  }, [data])

  return (
    <Button
      text={intl.get('send_course_size_report')}
      disabled={isSending}
      onClick={() => {
        setIsSending(true)
        sendCourseSizeReport()
      }}
    />
  )
}

type ChooserProps = {
  label: string,
  icon: any,
  description?: string,
  onClick?: () => void,
  disabled?: boolean,
}
const Chooser = (props: ChooserProps) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      style={{width: 192, height: 196}}
      className={`bg-white flex flex-col justify-center items-center border border-gray-300 border-dashed rounded ${props.disabled && 'cursor-not-allowed'} pt-4`}>
      {/* icon */}
      {React.cloneElement(props.icon, {className: 'mb-2'})}
      {/* title/label */}
      <span className={`text-deepgray font-medium text-xl ${!props.description && 'mt-4'}`}>{props.label}</span>
      {/* description */}
      {props.description &&
      <div className="flex items-center justify-center mx-auto flex-1">
        <p className="text-medgray text-sm text-center py-2 px-3">
          {props.description}
        </p>
      </div>
      }
    </button>
  )
}

const ImportContentIntoCourse = ({ courseId, onClose }: {courseId: string, onClose: () => void }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const activeWorkspaceId = useActiveWorkspaceId()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [questionsType, setQuestionsType] = useState<'questions' | 'assessments'>('questions')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [uploadQuestions] = useMutation<{}, ImportCourseQuestionsVars>(importCourseQuestionsMutation)

  const handleClick = (questionType: 'questions' | 'assessments') => {
    setQuestionsType(questionType)
    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      alert('no selected file')
      return
    }

    const file = e.target.files[0]
    setSelectedFile(file)

    handleFileUpload(file)
  }

  const handleFileUpload = async (file: File) => {
    if (!file) {
      alert('Please select a file.')
      return
    }

    const presignedUrl: PresignedUrl = await getPresignedUrl(
      appData?.appState.loggedInAs.token || '',
      file.name,
      file.type,
      file.size,
      'imports:questionsImport',
    )

    onClose()
    let modal

    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener('loadstart', () => {
      modal = showModal({
        title: intl.get('uploading'),
        content: intl.get('program_create_import_file_loading'),
        hideFooter: true,
      })
    })

    xhr.addEventListener('load', () => {
      modal && modal.close()
      if (xhr.status === 200) {
        uploadQuestions({ variables: { courseId, questionsUrl: presignedUrl.data.url, questionsType: questionsType, organizationId: activeWorkspaceId }})

        setTimeout(() => {
          modal && modal.close()
          showModal({
            title: intl.get('success'),
            component: (
              <>
                <div>{intl.get('file_successfully_uploaded')}</div>
                <div className="my-4 font-bold">
                  {intl.get('process_depending_on_number_of_questions')}
                </div>
                <div>
                  {intl.get('do_not_see_session_reload')}
                </div>
              </>
            ),
            primaryText: intl.get('refresh_content'),
            primaryAction: () => client.refetchQueries({ include: ['GetChaptersForCourse'] }),
            secondaryButton: false,
          })
        }, 3000)
      } else {
        showModal({
          title: intl.get('error_dialog_title'),
          content: intl.get('global_toast_error_message'),
          primaryText: intl.get('ok'),
          secondaryButton: false,
        })
      }
    })

    xhr.addEventListener('error', (err) => {
      showModal({
        title: intl.get('error_dialog_title'),
        content: intl.get(`global_toast_error_message - ${err}`),
        primaryText: intl.get('ok'),
        secondaryButton: false,
      })
    })

    xhr.open('PUT', presignedUrl.data.presignedUrl)

    Object.entries(presignedUrl.data.headers).map((h) => {
      const [header, value] = h
      if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
      xhr.setRequestHeader(header, value)
    })

    xhr.send(file)
  }
  return (
    <div className="mx-auto mb-5">
      <div className="flex justify-center items-center text-2xl font-bold">{intl.get('import_content_into_program')}</div>
      <input type="file" ref={inputRef} onChange={handleFileChange} disabled={false} readOnly={false} accept="text/csv" style={{ display: 'none' }}/>
      <div className="flex flex-row justify-center items-center space-x-4 mt-5">
        <Chooser onClick={() => handleClick('assessments')} label={intl.get('course_assessments')} icon={<AssessmentCheckmarkIcon className="w-5 h-5" />} description={intl.get('import_assessments_csv')}/>
        <Chooser onClick={() => handleClick('questions')} label={intl.get('multiple_choice_questions')} icon={<MultiChoiceNoteIcon className="w-5 h-5" />} description={intl.get('import_multiple_choice_csv')} />
      </div>
    </div>
  )
}

export const QuestionsUploader = ({ courseId, onClose }: {courseId: string, onClose: () => void }) => {

  const showChooser = () => {
    const modal = showModal({
      component: <ImportContentIntoCourse courseId={courseId} onClose={() => {
        onClose()
        modal.close()
      }} />,
      onlyContent: true,
    })
  }

  const showQuestionsModal = () => {
    const modal = showModal({
      component: <div className="p-8">
        <div className="flex justify-center items-center text-2xl">{intl.get('import_questions_or_assessments')}</div>
        <div className="px-4 mt-10">
          <img className="max-w-full max-h-[340px] mx-auto" src="/images/upload-questions-or-assessments.png" />
          <br />
      </div>
        <div className="w-full flex flex-row items-center justify-between space-x-1 mt-10" data-test="modal-buttons">
          <a href="https://cdn0.gnowbe.com/Gnowbe+Import+Test+Question+Sample.csv" className="underline" download>
            {intl.get('download_sample_csv')}
          </a>
          <div className="flex flex-row items-center justify-end gap-4">
            <Button
              text="Learn More"
              onClick={() => {
                modal.close()
                window.open('https://gnowbe.zendesk.com/hc/en-us/articles/37674582848531-Import-Multiple-Choice-or-Asssessment-questions-directly-into-your-Gnowbe-program', '_blank')
              }}
            />
            <Button
              type="primary"
              text={intl.get('global_continue')}
              onClick={() => {
                modal.close()
                showChooser()
              }}
            />
          </div>
        </div>
      </div>,
      onlyContent: true,
      className: 'post',
    },
    )
  }


  return (
    <div className="flex flex-row items-center justify-between w-full">
      {/* <input type="file" ref={inputRef} onChange={handleFileChange} disabled={false} readOnly={false} accept="text/csv" style={{ display: 'none' }}/> */}
      <div className="group inline-block relative">
        <button className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" data-test="import-qa" onClick={showQuestionsModal}>
          {intl.get('import_questions_and_answers')} (.csv)
        </button>
        <div className="tooltip whitespace-nowrap text-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible bg-deepgray text-white text-center rounded py-1 px-2 absolute z-10 bottom-14 left-1/2 transform -translate-x-1/2">
          {intl.get('import_questions_and_answers')} (.csv)
        </div>
      </div>
    </div>
  )
}


export const PptUploader2 = ({ courseId, chapterCount, isImportingPpt, upsell, v2, onClose, onlyIcon, disabled, isMenuItem }: { courseId: string, chapterCount?: number, isImportingPpt?: boolean, v2?: boolean, upsell: any, onClose: () => void, onlyIcon?: boolean, disabled?: boolean, isMenuItem?: boolean }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [selectedFile, setSelectedFile] = useState<File>()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [uploadPpt] = useMutation<{}, ImportPPTCourseVars>(importPPTCourseMutation)

  const handleClick = () => {
    if (upsell) {
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: <UpsellModal type="starter" track="import_powerpoint" close={() => modal.close()} />,
        onlyContent: true,
        className: 'add-action-modal',
      })
      return
    }

    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      alert('no selected file')
      return
    }

    const file = e.target.files[0]
    setSelectedFile(file)

    handleFileUpload(file)
  }

  const handleFileUpload = async (file: File) => {
    if (!file) {
      alert('Please select a file.')
      return
    }

    if (file.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      alert('Please select a .pptx file.')
      return
    }

    const presignedUrl: PresignedUrl = await getPresignedUrl(
      appData?.appState.loggedInAs.token || '',
      file.name,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      file.size,
      'imports:pptImport',
    )

    onClose()
    let modal

    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener('loadstart', () => {
      modal = showModal({
        title: intl.get('uploading'),
        content: intl.get('program_create_import_file_loading'),
        hideFooter: true,
      })
    })

    xhr.addEventListener('load', () => {
      modal && modal.close()
      if (xhr.status === 200) {
        uploadPpt({ variables: { courseId, pptUrl: presignedUrl.data.url } })

        setTimeout(() => {
          modal && modal.close()
          showModal({
            title: intl.get('success'),
            component: (
              <>
                <div>{intl.get('file_successfully_uploaded')}</div>
                <div className="my-4 font-bold">
                  Processing might take a while depending on the number of slides in the PPT file.
                </div>
                <div>
                  {intl.get('process_depending_on_number_of_questions')}
                </div>
              </>
            ),
            primaryText: intl.get('refresh_content'),
            primaryAction: () => client.refetchQueries({ include: ['GetChaptersForCourse'] }),
            secondaryButton: false,
          })
        }, 3000)
      } else {
        showModal({
          title: intl.get('error_dialog_title'),
          content: intl.get('global_toast_error_message'),
          primaryText: intl.get('ok'),
          secondaryButton: false,
        })
      }
    })

    xhr.addEventListener('error', (err) => {
      showModal({
        title: intl.get('error_dialog_title'),
        content: intl.get(`global_toast_error_message - ${err}`),
        primaryText: intl.get('ok'),
        secondaryButton: false,
      })
    })

    xhr.open('PUT', presignedUrl.data.presignedUrl)

    Object.entries(presignedUrl.data.headers).map((h) => {
      const [header, value] = h
      if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
      xhr.setRequestHeader(header, value)
    })

    xhr.send(file)
  }

  const showInfo = () => {
    const modal = showModal({
      title: intl.get('program_edit_import_ppxt_dialog_title'),
      content: intl.get('program_edit_import_ppxt_dialog_message'),
      primaryText: intl.get('global_upload'),
      primaryAction: () => handleClick(),
      secondaryText: intl.get('global_close'),
    })
  }


  return (
    <div className={`flex flex-row items-center justify-between ${isMenuItem ? 'w-full hover:bg-gray-100 hover:text-gray-900' : 'px-2'}`}>
      <input type="file" ref={inputRef} onChange={handleFileChange} disabled={false} readOnly={false} accept=".pptx" style={{ display: 'none' }}/>
      <div className="group inline-block relative">
        <button className={`${!isMenuItem ? 'btn-v2 inline-flex items-center justify-center border-gray-300 py-3' : 'menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700'}`} onClick={handleClick} disabled={disabled}>
          {!isMenuItem && <svg className={`inline-block ${!onlyIcon && 'mr-2'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99992 13.6667C1.54159 13.6667 1.14909 13.5033 0.82242 13.1767C0.495753 12.85 0.332698 12.4578 0.333253 12V10.3333C0.333253 10.0972 0.413253 9.89917 0.573253 9.73917C0.733253 9.57917 0.931031 9.49944 1.16659 9.5C1.4027 9.5 1.60075 9.58 1.76075 9.74C1.92075 9.9 2.00048 10.0978 1.99992 10.3333V12H11.9999V10.3333C11.9999 10.0972 12.0799 9.89917 12.2399 9.73917C12.3999 9.57917 12.5977 9.49944 12.8333 9.5C13.0694 9.5 13.2674 9.58 13.4274 9.74C13.5874 9.9 13.6671 10.0978 13.6666 10.3333V12C13.6666 12.4583 13.5033 12.8508 13.1766 13.1775C12.8499 13.5042 12.4577 13.6672 11.9999 13.6667H1.99992ZM6.16659 3.54167L4.60409 5.10417C4.43742 5.27083 4.23936 5.35083 4.00992 5.34417C3.78048 5.3375 3.5827 5.25056 3.41659 5.08333C3.26381 4.91667 3.18381 4.72222 3.17659 4.5C3.16936 4.27778 3.24936 4.08333 3.41659 3.91667L6.41659 0.916666C6.49992 0.833333 6.5902 0.774444 6.68742 0.74C6.78464 0.705555 6.88881 0.688056 6.99992 0.6875C7.11103 0.6875 7.2152 0.705 7.31242 0.74C7.40964 0.775 7.49992 0.833889 7.58325 0.916666L10.5833 3.91667C10.7499 4.08333 10.8299 4.27778 10.8233 4.5C10.8166 4.72222 10.7366 4.91667 10.5833 5.08333C10.4166 5.25 10.2185 5.33694 9.98909 5.34417C9.75964 5.35139 9.56186 5.27139 9.39575 5.10417L7.83325 3.54167V9.5C7.83325 9.73611 7.75325 9.93417 7.59325 10.0942C7.43325 10.2542 7.23548 10.3339 6.99992 10.3333C6.76381 10.3333 6.56575 10.2533 6.40575 10.0933C6.24575 9.93333 6.16603 9.73555 6.16659 9.5V3.54167Z" fill="#101010"/>
          </svg>}
          {!onlyIcon && !isMenuItem ? `${intl.get('global_import')}` : !onlyIcon && isMenuItem && 'Import Power Point (.pptx)'}
        </button>
        <div className="tooltip whitespace-nowrap text-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible bg-deepgray text-white text-center rounded py-1 px-2 absolute z-10 bottom-14 left-1/2 transform -translate-x-1/2">
          {intl.get('program_template_import_powerpoint')} (.pptx)
        </div>
      </div>
    </div>
  )
}

export const PptUploader = ({ courseId, chapterCount, isImportingPpt, upsell, v2, onClose }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [uploadPpt] = useMutation<{}, ImportPPTCourseVars>(importPPTCourseMutation)
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const presignedUrl: PresignedUrl = await getPresignedUrl(
        appData?.appState.loggedInAs.token || '',
        acceptedFiles[0].name,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        acceptedFiles[0].size,
        'imports:pptImport',
      )

      onClose()
      let modal
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            uploadPpt({ variables: { courseId, pptUrl: presignedUrl.data.url }})

            setTimeout(() => {
              modal && modal.close()
              showModal({
                title: intl.get('success'),
                component: (
                  <>
                    <div>{intl.get('file_successfully_uploaded')}</div>
                    <div className="my-4 font-bold">Processing might take a while depending on the number of slides in the PPT file.</div>
                    <div>{intl.get('process_depending_on_number_of_questions')}</div>
                  </>
                ),
                primaryText: intl.get('refresh_content'),
                primaryAction: () => client.refetchQueries({ include: ['GetChaptersForCourse'] }),
                secondaryButton: false,
              })
            }, 3000)
          }
          else {
            showModal({
              title: intl.get('error_dialog_title'),
              content: intl.get('global_toast_error_message'),
              primaryText: intl.get('ok'),
              secondaryButton: false,
            })
          }
        }
      }
      xhr.upload.addEventListener('loadstart', () => {
        modal = showModal({
          title: intl.get('uploading'),
          content: intl.get('program_create_import_file_loading'),
          hideFooter: true,
        })
      })

      // xhr.upload.addEventListener('progress', (e) => {
      //   if (!updateProgress) return
      //   return updateProgress((e.loaded / e.total) * 100)
      // })
      xhr.addEventListener('error', (err) => {
        showModal({
          title: intl.get('error_dialog_title'),
          content: intl.get(`global_toast_error_message - ${err}`),
          primaryText: intl.get('ok'),
          secondaryButton: false,
        })
      })
      // xhr.addEventListener('abort', () => rej('errors.upload_aborted'))
      // xhr.addEventListener('load', () => res(data.url))
      xhr.open('PUT', presignedUrl.data.presignedUrl)
      Object.entries(presignedUrl.data.headers).map((h) => {
        const [header, value] = h
        if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
        xhr.setRequestHeader(header, value)
      })
      xhr.send(acceptedFiles[0])
    },
  })

  const uploadCheck = () => {
    if (upsell) {
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: <UpsellModal type="starter" track="import_powerpoint" close={() => modal.close()} />,
        onlyContent: true,
        className: 'add-action-modal',
      })
      return
    }

    if (chapterCount <= 1) {
      return upload()
    }

    showModal({
      title: intl.get('program_edit_import_ppxt_dialog_title'),
      content: intl.get('program_edit_import_ppxt_dialog_message'),
      primaryText: intl.get('program_edit_import_pptx_dialog_upload'),
      primaryAction: upload,
    })
  }

  const upload = () => {
    open()
    // uploadFile({
    //   authToken: appData?.appState.loggedInAs.token || '',
    //   mimetypes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
    //   target: 'imports:pptImport',
    // }).then((file) => {
    //   console.log('wqereqwr')
    //   uploadPpt({ variables: { courseId, pptUrl: file.url }})
    //   updateAppCache('isImportingPpt', true)
    //   showModal({
    //     title: intl.get('uploading'),
    //     content: intl.get('program_create_import_file_loading'),
    //     primaryText: intl.get('dialog_notes_view_chapter'),
    //     primaryAction: () => history.push('/learn'),
    //     secondaryText: intl.get('global_close'),
    //   })
    // }).catch((err) => {
    //   console.log(err)
    // })
  }

  if (v2) {
    return (
      <button className="px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => {
        uploadCheck()
      }}>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
        </div>

        {intl.get('program_template_import_powerpoint')} (.pptx)

        {upsell && <i className="icon-lock-solid ml-2" />}
      </button>
    )
  }

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span className="text-lg">
              {intl.getHTML('create_program_upload_powerpoint_file')}

              {upsell && <Lock className="w-4 h-4 ml-1 inline-block" tooltip={intl.get('pptx_template_description')} />}
            </span>

            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8 space-y-4">
              <Button
                type="primary"
                text={isImportingPpt ? intl.get('uploading') : intl.get('program_edit_import_pptx')}
                rightIcon={upsell ? 'icon-lock-solid' : undefined}
                fullWidth={true}
                disabled={isImportingPpt}
                tooltip={upsell ? intl.get('upsell_paid_plans_dialog') : undefined}
                onClick={uploadCheck}
              />

              <Alert variant="warning" className="text-sm py-2" onClose={() => null}>
                <div dangerouslySetInnerHTML={{ __html: nl2br(intl.get('program_pptx_file_types')) }} />
              </Alert>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

const LearningModePicker = ({ defaultOpen, chaptersUnlocked, update }) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('course_learning_mode')}</span>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pl-8 pr-4 pb-4 space-y-4">
              <Tippy placement="right" content="Participants will be able to browse the whole program and access all the actions. This is recommended for reference materials.">
                <div>
                  <label className="inline-flex mb-0">
                    <input
                      type="radio"
                      defaultChecked={chaptersUnlocked === 'unlocked'}
                      className="mt-1.5 h-4 w-4 text-team cursor-pointer"
                      name="radio-sizes"
                      value="unlocked"
                      onChange={e => update('chaptersUnlocked', 'unlocked')}
                    />
                    <div className="ml-4">
                      <div className="font-bold">{intl.get('course_unlocked_mode')}</div>
                      <div className="text-sm text-gray-500">{intl.get('learning_mode_unlocked')}</div>
                    </div>
                  </label>
                </div>
              </Tippy>
              <Tippy placement="right" content="Participants will progress and unlock one session every day. This is a timed-release, drip style learning which is the most effective for building the habit of learning.">
                <div>
                  <label className="inline-flex mb-0">
                    <input
                      type="radio"
                      defaultChecked={chaptersUnlocked === 'daily'}
                      className="mt-1.5 h-4 w-4 text-team cursor-pointer"
                      name="radio-sizes"
                      value="daily"
                      onChange={e => update('chaptersUnlocked', 'daily')}
                    />
                    <div className="ml-4">
                      <div className="font-bold">{intl.get('course_daily_mode')}</div>
                      <div className="text-sm text-gray-500">{intl.get('learning_mode_daily')}</div>
                    </div>
                  </label>
                </div>
              </Tippy>
              <Tippy placement="right" content="On Day 1 only the first session is available. Participants will unlock session by session by completing all the actions in each session. This can be done in one streak, if the participant is sufficiently motivated.">
                <div>
                  <label className="inline-flex mb-0">
                    <input
                      type="radio"
                      defaultChecked={chaptersUnlocked === 'streak'}
                      className="mt-1.5 h-4 w-4 text-team cursor-pointer"
                      name="radio-sizes"
                      value="daily"
                      onChange={e => update('chaptersUnlocked', 'streak')}
                    />
                    <div className="ml-4">
                      <div className="font-bold">{intl.get('course_streak_mode')}</div>
                      <div className="text-sm text-gray-500">{intl.get('learning_mode_streak')}</div>
                    </div>
                  </label>
                </div>
              </Tippy>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

const Details = ({ course, v2, update }: { course: Course, v2?: boolean, update: (f, v) => void }) => {
  const skillsHash = MD5((course.skills || []).join('-') || '')
  const { title, language, titleEnglish, difficulty, category, categoryText, skills, cpeHours } = course

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('details_text')}</span>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className={`pb-6 px-8 ${v2 ? 'grid grid-cols-1 md:grid-cols-2 gap-4' : undefined}`}>
              <LanguagePicker
                language={language || 'en'}
                onChange={l => update('language', l.toLowerCase())}
              />

              {language?.toLowerCase() !== 'en'
                ? <EnglishTitleField
                    courseId={course.id}
                    englishTitle={titleEnglish || title}
                    onChange={t => update('titleEnglish', t)}
                  />
                : <div />
              }

              <DifficultyPicker
                difficulty={difficulty || 'basic'}
                onChange={d => update('difficulty', d)}
              />

              <CategoryPicker
                key={`${course.id}-category-${category}`}
                defaultCategory={category || 'LF'}
                defaultCategoryText={categoryText || 'Life Skills'}
                onChange={v => update('category', v)}
                onSelect={(v, i) => update('categoryText', v)}
              />

              <SkillsPicker
                key={`${skillsHash}`}
                skills={skills || []}
                onUpdate={(v, i) => update('skills', v)}
              />

              <div className="form-group">
                <Tippy content="Continuing Professional Education hours" placement="right">
                  <label htmlFor="cpe_hours" className="block font-medium leading-5 text-gray-700">{intl.get('cpe_hours')}</label>
                </Tippy>

                <SimpleEditable
                  id="cpe_hours"
                  key={`${course.id}-cpe`}
                  defaultValue={cpeHours}
                  placeholder={intl.get('cpe_hours')}
                  isEditing={true}
                  fullWidth={true}
                  classname="mt-2"
                  update={(v) => {
                    if (!isNaN(v)) {
                      update('cpeHours', Number(v))
                    }
                    else {
                      showAlert(intl.get('error_field_number'), 'danger')
                    }
                  }}
                />
              </div>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

type ContentSettingsProps = {
  workbookDisabled: boolean;
  allowMediaDownload: boolean;
  denyContentCopy: boolean;
  owner: CourseOwner|null;
  update: (k, v) => void;
}

const ContentSettings = ({ workbookDisabled, allowMediaDownload, denyContentCopy, owner, update }: ContentSettingsProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('global_content')}</span>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8">
              <Toggle
                id="workbookDisabled"
                defaultChecked={!workbookDisabled}
                // label={intl.get('course_disable_workbook')}
                label={intl.get('course_enable_workbook')}
                onChange={e => update('workbookDisabled', !e)}
              />

              <Toggle
                id="allowMediaDownload"
                defaultChecked={allowMediaDownload}
                label={intl.get('course_allow_download')}
                onChange={e => update('allowMediaDownload', e)}
              />

              <Toggle
                id="denyContentCopy"
                defaultChecked={!denyContentCopy}
                // label={intl.get('course_deny_copy')}
                label={intl.get('course_allow_copy')}
                onChange={e => update('denyContentCopy', !e)}
              />
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

const AdditionalButtons = ({  userEmail, courseId, title, isScorm, subsCount, appData, subData }) => {
  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const sandboxWorkspaceId = orgData?.res.organizations.find(o => o.name.toLowerCase().includes('sandbox community') || o.name.toLowerCase().includes('test groups'))?.id
  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId') || ''

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('global_other')}</span>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8">
              {sandboxWorkspaceId !== activeWorkspaceId &&  <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium">
                In order to delete this program switch to the Sandbox Workspace
              </div>}
              <div className="form-group grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
                <DuplicateCourseButton
                  courseId={courseId}
                  isScorm={isScorm}
                />

                <ExportCourseContent courseId={courseId} email={userEmail} />

                {!isScorm && (
                  <SendCourseSizeReport
                    courseId={courseId}
                  />
                )}
                <DeleteCourseButton
                  courseId={courseId}
                  title={title}
                  subsCount={subsCount}
                  sandboxWorkspaceId={sandboxWorkspaceId}
                  activeWorkspaceId={activeWorkspaceId}
                />
              </div>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

const TranslationButtons = ({ courseId, subData, appData }) => {
  const navigate = useNavigate()

  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId') || ''

  const isDuplicateAndTranslateCourseEnabledForActiveWorkspace  = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('duplicateAndTranslateCourse')
  const isDuplicateAndTranslateCourseEnabledForAnyWorkspace  = orgData?.res.organizations.some(o => o.featureFlags.includes('duplicateAndTranslateCourse'))

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('sidebar_translation_section_translation')}</span>
            {/* remove new tag after some time */}
            <NewTag y='top-5' x='left-32'/>

            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8">
              {(!isDuplicateAndTranslateCourseEnabledForActiveWorkspace && isDuplicateAndTranslateCourseEnabledForAnyWorkspace) ? <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium left">
                {intl.get('sidebar_translation_section_translation_distribute')}
              </div>
              : !isDuplicateAndTranslateCourseEnabledForAnyWorkspace && <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium left">
                {intl.get('sidebar_translation_section_translation_billing_admin')}
            </div>

            }
              <div className="form-group grid grid-row gap-2 w-full md:w-1/2">

              <TranslateCourseButton courseId={courseId} disabled={!isDuplicateAndTranslateCourseEnabledForActiveWorkspace} metadata={subData?.user.metadata || {}}/>

              {subData.user.roles.some(r => r === 'admin' || r === 'superEditor') && (
                <>
                  <Button
                    text={intl.get('sidebar_translation_section_export')}
                    onClick={() => exportCourse(courseId)}
                  />
                    {/* remember to check the course id */}
                    {/* remember to remove the english column */}
                  <Button
                    text={intl.get('sidebar_translation_section_import')}
                    onClick={() => {
                      navigate('/import')
                      // showModal({
                      //   title: 'Import Translation',
                      //   component: <ApolloProvider client={client}><ImportCourseModal /></ApolloProvider>,
                      // })
                    }}
                  />
                </>
                )}

              </div>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

const EditButtons = ({ courseId, email }) => {
  const navigate = useNavigate()
  return null

  return (
    <div className="border-t border-gray-200 p-4 pb-0 space-y-2">
      <Button
        text={intl.get('share_text')}
        icon="icon-upload-light"
        fullWidth={true}
        onClick={() => {
          const win = window.open(`${BASE_DASHBOARD_URL}/program/${courseId}/status?continueAs=${encodeURIComponent(email)}`)
          if (win) win.opener = null
        }}
      />

      <Button
        text={intl.get('program_edit_sessions')}
        icon="icon-session-light"
        fullWidth={true}
        onClick={() => navigate('/learn')}
      />

      {/* <Button
        text="Back to View Mode"
        icon="icon-eye-light"
        type="team"
        fullWidth={true}
        onClick={() => updateAppCache('isEditing', false)}
      /> */}
    </div>
  )
}

export const ShareCourse = ({ courseId, onClose, courseName }) => {
  const [email, setEmail] = useState('')
  const [shareUrl, setShareUrl] = useState('')
  const appData = client.readQuery<AppState>({ query: getAppStateQuery })

  const [inviteCurator] = useMutation(inviteCuratorMutation, {
    onCompleted: () => {
      showAlert(intl.get('invite_co_curator_success'))
      track({
        event: 'Co-curator Invited',
        variables: {
          invitedUserEmail: email,
          programId: courseId,
          invitedById: appData?.appState?.loggedInAs?.uid || '' ,
          action: 'add',
          programName: courseName,
        },
      })
      onClose()
    },
    onError: (err) => {
      showAlert(`Error - ${err.message}`, 'danger')
      onClose()
    },
  })
  const shareField = useRef<any>(null)

  const { data, loading } = useQuery<GetCourseEditorsRes, GetCourseEditorsVars>(getCourseEditorsQuery, { variables: { courseId }})

  const selectField = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
    shareField.current && shareField.current.select()
  }

  const sendInvite = async () => {
    if (!email) {
      return showAlert(intl.get('reward_error_title'), 'danger')
    }

    const res = await inviteCurator({ variables: {
      courseId,
      userIdOrEmail: email,
      addRemove: 'add',
    }})
  }

  const sectionCn = classNames({
    'mt-6 pt-6 border-t border-gray-300': shareUrl,
  })

  return (
    <>
      <section className={sectionCn}>
        <p className="mb-0">{courseName ? intl.getHTML('invite_co_curator_description', { 0: courseName }): intl.get('invite_co_curator_description2')}</p>

        <div className="font-bold mb-2">{intl.get('email')}</div>

        <div className="flex">
          <input
            type="text"
            className="flex-1 mr-4 form-input"
            placeholder="example@email.com"
            onChange={e => setEmail(e.target.value)}
          />

          <Button
            text={intl.get('invite')}
            type="team"
            icon="icon-users-solid"
            onClick={sendInvite}
          />
        </div>
      </section>

      <section>
        <div className="text-lg font-bold mt-6">{intl.get('invite_curators')}</div>

        {data && data.res?.users?.length > 0 && (
          <ul className="mt-4 pl-4 max-h-24 overflow-y-auto">
            {data.res.users.map((user) => {
              return (
                <li key={user.id}>{user.email}</li>
              )
            })}
          </ul>
        )}

        {loading && (
          <div>Curators loading...</div>
        )}
      </section>
    </>
  )
}

const ImportCourseModal = () => {
  const [updateCourseBulk, updateCourseBulkRes] = useMutation(updateCourseBulkMutation)

  useEffect(() => {
    if (!updateCourseBulkRes) return
    if (updateCourseBulkRes.error) {
      console.log(updateCourseBulkRes.error)
    }
  }, [updateCourseBulkRes])

  const fileInfo = (e) => {
    const file = e.target.files[0]
    if (file.name.split('.')[1].toUpperCase() !== 'CSV') {
      alert('Invalid csv file !')
      e.target.parentNode.reset()
      return
    }

    document.getElementById('file_info')!.innerHTML = `<p>File Name: ${file.name} | ${file.size} Bytes.</p>`
  }

  const handleFileSelect = () => {
    const file = (document.getElementById('the_file') as any)!.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (file) => {
      if (!file?.target?.result) return

      // regex to find missing open <ul> in old exported files
      // const a = /: ?(.*? ?[\+]{5} ?.*?) ?##### ?/g

      const content = file.target.result
      const d = importCourse(content)

      updateCourseBulk({
        variables: d,
        update: () => {
          if (confirm('import finished, reload page to see the results')) {
            window.location.reload()
            // disabling edit mode is enough
          }
        },
      })
    }
    reader.readAsText(file)
  }

  return (
    <div>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleFileSelect()
      }} id="the_form">
        <input type="file" id="the_file" required accept=".csv" onChange={fileInfo} />
        <input type="submit" value="Go" />
      </form>
      <div id="file_info" />
    </div>
  )
}

export const ScormUpload = ({ courseId, scormSettings, v2 }: { courseId: string, scormSettings: ScormSettings|null, v2?: boolean }) => {
  const [uploadScormCourseContent] = useMutation<UploadScormCourseContentRes, UploadScormCourseContentVars>(uploadScormCourseContentMutation)
  const noScormFile = !scormSettings || !['complete', 'running'].includes(scormSettings.uploadStatus)

  const upload = async () => {
    try {
      const token = getAuthTokens()
      const res = await uploadFile({ authToken: token, mimetypes: ['zip', 'application/octet-stream', 'application/zip', 'application/x-zip', 'application/x-zip-compressed'], target: `course:${courseId}`, services: ['local'], courseId })
      uploadScormCourseContent({
        variables: {
          courseId,
          fileUrl: res.url,
        },
        onCompleted: () => {
          track({
            event: 'Scorm Uploaded',
            variables: {
              programId: courseId,
            }
          })
        },
      })
    } catch (err: any) {
      const error = Error(err)
      console.log('error', error.message)
    }
  }

  const onUpload = () => {
    if (noScormFile) {
      return upload()
    }
    return showModal({
      title: intl.get('global_are_you_sure_header'),
      content: intl.get('scorm_note_replace_confirm'),
      primaryAction: () => upload(),
    })
  }

  return (
    <section className={!v2 ? 'p-4' : 'py-4 border-t border-b border-gray-200 mb-4'}>
      <div className="text-xl font-bold text-deepgray mb-4">
        {intl.get('program_template_upload_scorm_file')}
      </div>

      <Button type="primary" fullWidth={true} onClick={onUpload} text={noScormFile ? intl.get('scorm_upload_file') : intl.get('scorm_replace_file')} />

      <div className="bg-yellow-100 p-4 rounded-md mt-4 text-sm text-yellow-900">
        <ul className="space-y-4">
          <li>{intl.get('scorm_note_supported')}</li>
          <li>{intl.get('scorm_note_size')}</li>
          <li>{intl.get('scorm_note_replace')}</li>
        </ul>
      </div>
    </section>
  )
}

export const ChangeOwner = ({ currentOwner, alt }: { currentOwner: CourseOwner|null, alt?: boolean }) => {
  const { data, error } = useQuery<AppState>(getAppStateQuery)
  const [getLinkedOrgs, { data: orgData, loading: orgLoading }] = useLazyQuery<GetUsersLinkedToOrganizationsRes, GetUsersLinkedToOrganizationsVars>(getUsersLinkedToOrganizationsQuery)
  const [update] = useMutation(updateCourseMutation)

  useEffect(() => {
    getLinkedOrgs({
      variables: {
        userId: data?.appState.loggedInAs.uid || '' ,
        includeLearnerOrgs: false,
        includeManagerOrgs: true,
        includeAgentOrgs: true,
        includeEditorOrgs: true,
        onlyClientOrgs: true,
      },
    })
  }, [data])

  const updateOwner = (e) => {
    if (!data) return

    const [_, courseId] = data.appState.currentCompanyIdCourseId.split('-') || ['', '']
    const selectedData = e.target.options[e.target.selectedIndex].dataset

    if (data.appState.loggedInAs.uid === selectedData.id) {
      return update({
        variables: {
          courseId,
          courseUpdate: {
            owner: {
              id: selectedData.id,
              type: selectedData.type,
            },
          },
        },
      })
    }

    showModal({
      title: intl.get('dialog_are_you_sure'),
      content: intl.get('transfer_owership_confirm'),
      primaryAction: () => {
        update({
          variables: {
            courseId,
            courseUpdate: {
              owner: {
                id: selectedData.id,
                type: selectedData.type,
              },
            },
          },
        })
      },
    })
  }

  if (!data || !orgData) return null

  const canChangeOwnership = currentOwner === null || (data.appState.loggedInAs.uid === currentOwner.id && currentOwner.type === 'user' && orgData.organizations.length > 0)

  if (alt) {
    return (
      <>
        <div className="text-xl font-bold text-deepgray mb-2">
          {intl.get('transfer_ownership_title')}
        </div>

        <div className="mb-4 text-sm">{intl.get('transfer_ownership_text')}</div>

        <select
          className="border-team mt-2 block border bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 editable"
          value={currentOwner?.id || '-'}
          onChange={updateOwner}
          disabled={!canChangeOwnership}>
            {!currentOwner && <option value="-">{intl.get('transfer_ownership_choose')}</option>}
            <option value={currentOwner?.user?.id || currentOwner?.organization?.id || data.appState.loggedInAs.uid}
              data-id={currentOwner?.user?.id || currentOwner?.organization?.id || data.appState.loggedInAs.uid} data-type={currentOwner?.organization ? 'organization' : 'user'}>
              {(!currentOwner || (data.appState.loggedInAs.uid === currentOwner?.id)) ? intl.get('transfer_ownership_you') : currentOwner?.user?.profile.fullName || currentOwner?.organization?.name}
            </option>
            {orgData.organizations.map(o => <option key={o.id} value={o.id} data-id={o.id} data-type="organization">{o.name}</option>)}
        </select>
      </>
    )
  }

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton className={disclosureClassnames}>
            <span>{intl.get('transfer_ownership_title')}</span>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
          </DisclosureButton>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0">
            <DisclosurePanel className="pb-6 px-8">
              <div>
                <div className="mb-4">{intl.get('transfer_ownership_text')}</div>

                <select
                  className="border-team mt-2 w-72 block border bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm editable"
                  value={currentOwner?.id || '-'}
                  onChange={updateOwner}
                  disabled={!canChangeOwnership}>
                    {!currentOwner && <option value="-">{intl.get('transfer_ownership_choose')}</option>}
                    <option value={currentOwner?.user?.id || currentOwner?.organization?.id || data.appState.loggedInAs.uid}
                      data-id={currentOwner?.user?.id || currentOwner?.organization?.id || data.appState.loggedInAs.uid} data-type={currentOwner?.organization ? 'organization' : 'user'}>
                      {(!currentOwner || (data.appState.loggedInAs.uid === currentOwner?.id)) ? intl.get('transfer_ownership_you') : currentOwner?.user?.profile.fullName || currentOwner?.organization?.name}
                    </option>
                    {orgData.organizations.map(o => <option key={o.id} value={o.id} data-id={o.id} data-type="organization">{o.name}</option>)}
                </select>
              </div>
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

export const ShareButton = ({ courseId, location, className }: {courseId: string, location: string, className?: string}) => {
  const share = () => {
    trackButton({
      button: 'share_course',
      on_screen: location,
    })

    trackButtonEnhanced({
      button: 'Share Program',
      onScreen: location,
    })

    showModal({
      title: intl.get('invite_co_curator_title'),
      component: (
        <ShareCourse
          courseId={courseId}
          onClose={() => null}
          courseName={null}
        />
      ),
      secondaryButton: false,
      primaryText: intl.get('global_close'),
      hideFooter: true,
    })
  }

  return (
    <Tippy content={intl.get('invite_co_curator_tooltip')}>
      <Button
        text={intl.get('invite_co_curator_title')}
        icon="icon-users-solid"
        onClick={share}
        className={className}
      />
    </Tippy>
  )
}

const UploadCertificateSeal = ({ courseId, certSealUrl, upsell }: { courseId: string, certSealUrl: string|null, upsell: boolean }) => {
  const [uploading, setUploading] = useState(false)
  const { data } = useQuery<AppState>(getAppStateQuery)
  const [update] = useMutation(updateCourseMutation)

  const signedSeal = certSealUrl && getSignedValue(certSealUrl) || null

  const updateCourse = () => {
    if (upsell) {
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: <UpsellModal type="starter" track="certificate" close={() => modal.close()} />,
        onlyContent: true,
        className: 'add-action-modal',
      })
      return
    }

    setUploading(true)

    uploadFile({ authToken: data?.appState.loggedInAs.token || '', mimetypes: DEFAULT_MIMETYPES, target: `course:${courseId}:public`, courseId: courseId })
      .then((data) => {
        setUploading(false)
        update({
          variables: {
            courseId,
            courseUpdate: {
              certSealUrl: data.url,
            },
          },
        })
      })
      .catch(() => {
        setUploading(false)
      })
  }

  const removeSeal = () => {
    showModal({
      title: intl.get('delete_certificate_seal_title'),
      content: intl.get('delete_certificate_seal_text'),
      destructive: true,
      primaryText: intl.get('global_delete'),
      icon: 'icon-exclamation-triangle-light text-coral',
      primaryAction: () => {
        update({
          variables: {
            courseId,
            courseUpdate: {
              certSealUrl: '',
            },
          },
          update: () => {
            showAlert(intl.get('success'), 'success')
          },
        })
      },
    })
  }

  return (
    <div className="space-y-4 mt-4">
      {!upsell && (
        <div>
          <div className="font-bold mb-2">{intl.get('certificate_seal_header')}</div>
          <div className="p-4 bg-yellow-100 text-yellow-900 rounded-md text-sm">
            {intl.get('certificate_seal_text')}
          </div>
        </div>
      )}

      <div>
        <div className="font-bold mb-2">{intl.get('certificate_seal_header')}</div>
        <div className="flex gap-4 items-center">
          {signedSeal && (
            <div className="w-24 h-24 rounded-md">
              <img src={signedSeal} className="w-full h-full rounded-md object-cover" />
            </div>
          )}

          <div className="space-x-2">
            <Button
              // rightIcon={upsell ? 'icon-lock-solid' : undefined}
              disabled={uploading}
              text={signedSeal ? 'Replace' : intl.get('certificate_seal_button')}
              tooltip={upsell ? intl.get('upsell_paid_plans_dialog') : undefined}
              onClick={updateCourse}
            />

            {signedSeal && (
              <Button
                type="danger"
                text={intl.get('delete')}
                onClick={removeSeal}
              />
            )}
          </div>
        </div>
      </div>

    </div>
  )
}
